<template>
    <NuxtLayout name="default">
        <div class="row justify-content-center spacer-navigation spacer-footer">
            <div class="col-12 col-800-8">
                <div class="d-flex flex-column">
                    <h1 class="scale-6 scale-6--bold-uppercase">
                        {{ error.message }}
                    </h1>
                </div>
            </div>
            <div class="col-12 col-800-8">
                <FormButton
                    @click="reload"
                    :label="$t('error.reload')"
                    style="margin-right: 10px"
                />
                <FormButton
                    class="back-to-home"
                    :label="
                        error?.data ? JSON.parse(error.data).buttonText : 'Back'
                    "
                    @click="home"
                    component="NuxtLink"
                />
            </div>
        </div>
    </NuxtLayout>
</template>

<script setup>
import { reloadNuxtApp } from 'nuxt/app';
import { useI18n } from 'vue-i18n';

const props = defineProps({
    error: Object,
});
useHead({
    title: props?.error?.data
        ? JSON.parse(props.error.data).title
        : '404 - Page not found',
});

const { setLocale } = useI18n();
setLocale(props?.error?.data ? JSON.parse(props?.error?.data).language : 'de');

function home() {
    reloadNuxtApp({ path: '/de/' });
}

function reload() {
    reloadNuxtApp();
}
</script>

<style lang="scss" scoped>
.back-to-home {
    margin-top: clamp(40px, 0.35vw + 38.89px, 45px);
}
</style>
