import { parseURL } from 'ufo';

export function getTarget(url) {
    const config = useRuntimeConfig();
    if (url) {
        const parsedUrl = parseURL(url);
        if (
            parsedUrl &&
            parsedUrl.host &&
            !parsedUrl.host.includes(config.public.domain)
        ) {
            return '_blank';
        }
    }

    return null;
}
