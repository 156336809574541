import { default as SaferpayCallbackrv35JkTo6zMeta } from "/usr/src/app/pages/checkout/SaferpayCallback.vue?macro=true";
import { default as _91_46_46_46slug_93pvGVZEAWcBMeta } from "/usr/src/app/pages/[...slug].vue?macro=true";
import { default as Addn3V91uhFr3Meta } from "/usr/src/app/pages/account/address/Add.vue?macro=true";
import { default as EditjwDUcx0xBgMeta } from "/usr/src/app/pages/account/address/Edit.vue?macro=true";
import { default as Overview8TBUh1sCglMeta } from "/usr/src/app/pages/account/address/Overview.vue?macro=true";
import { default as Confirmation0cnIFDuTnEMeta } from "/usr/src/app/pages/account/email/Confirmation.vue?macro=true";
import { default as ConfirmedLVYjLIDW9zMeta } from "/usr/src/app/pages/account/email/Confirmed.vue?macro=true";
import { default as SentYtgRGXsuanMeta } from "/usr/src/app/pages/account/email/Sent.vue?macro=true";
import { default as MemberXcd3K9Oac1Meta } from "/usr/src/app/pages/account/Member.vue?macro=true";
import { default as OrderDetail9a7txqcZJxMeta } from "/usr/src/app/pages/account/OrderDetail.vue?macro=true";
import { default as Overview0AwmiEJ9ISMeta } from "/usr/src/app/pages/account/Overview.vue?macro=true";
import { default as RegistrationpcyjU8O365Meta } from "/usr/src/app/pages/account/Registration.vue?macro=true";
import { default as base0lXVpfAHTSMeta } from "/usr/src/app/pages/base.vue?macro=true";
import { default as AddressZ7kpmHC7V0Meta } from "/usr/src/app/pages/checkout/Address.vue?macro=true";
import { default as PreLoginYBzpMz37jgMeta } from "/usr/src/app/pages/checkout/PreLogin.vue?macro=true";
import { default as SaferpayMyPYuhOK5TMeta } from "/usr/src/app/pages/checkout/Saferpay.vue?macro=true";
import { default as Shipping1oZZnAgsVaMeta } from "/usr/src/app/pages/checkout/Shipping.vue?macro=true";
import { default as SummaryDoRytxW7t7Meta } from "/usr/src/app/pages/checkout/Summary.vue?macro=true";
import { default as ThankYouMO5fsgDbFTMeta } from "/usr/src/app/pages/checkout/ThankYou.vue?macro=true";
import { default as VideoEAW5P29eWWMeta } from "/usr/src/app/pages/cms/Video.vue?macro=true";
import { default as Courselq9MI9RVOMMeta } from "/usr/src/app/pages/course/Course.vue?macro=true";
import { default as Overview8954YDfq2uMeta } from "/usr/src/app/pages/course/Overview.vue?macro=true";
import { default as ErrorTC6LngnbbrMeta } from "/usr/src/app/pages/error/Error.vue?macro=true";
import { default as Overview99BFKsBzheMeta } from "/usr/src/app/pages/job/Overview.vue?macro=true";
import { default as PostinguEk4zc8CMvMeta } from "/usr/src/app/pages/job/Posting.vue?macro=true";
import { default as ImpressumNnwJOCDdREMeta } from "/usr/src/app/pages/legal/Impressum.vue?macro=true";
import { default as Detailw3w4d3JBm9Meta } from "/usr/src/app/pages/location/Detail.vue?macro=true";
import { default as Overvieww21Ce8BfMRMeta } from "/usr/src/app/pages/location/Overview.vue?macro=true";
import { default as CategoryVF8sPdAoELMeta } from "/usr/src/app/pages/magazine/Category.vue?macro=true";
import { default as Item8Iah1Eka2kMeta } from "/usr/src/app/pages/magazine/Item.vue?macro=true";
import { default as OverviewpW6kc9mZL5Meta } from "/usr/src/app/pages/magazine/Overview.vue?macro=true";
import { default as OutdatedXfMPF1TuokMeta } from "/usr/src/app/pages/Outdated.vue?macro=true";
import { default as previewRGYKYr0i5AMeta } from "/usr/src/app/pages/preview.vue?macro=true";
import { default as Catalogueh4o6Dl8fQPMeta } from "/usr/src/app/pages/product/Catalogue.vue?macro=true";
import { default as CategoryZIEegLiFDBMeta } from "/usr/src/app/pages/product/Category.vue?macro=true";
import { default as Detail6bfdkvOoNDMeta } from "/usr/src/app/pages/product/Detail.vue?macro=true";
import { default as SearchU77M4RgEh5Meta } from "/usr/src/app/pages/search/Search.vue?macro=true";
import { default as EmployeekMEogTDkTLMeta } from "/usr/src/app/pages/team/Employee.vue?macro=true";
import { default as Team8jhszmPOJVMeta } from "/usr/src/app/pages/team/Team.vue?macro=true";
export default [
  {
    name: SaferpayCallbackrv35JkTo6zMeta?.name ?? "checkout-redirect___de",
    path: SaferpayCallbackrv35JkTo6zMeta?.path ?? "/de/checkout/\\:kind/\\:orderNumber/\\:hash",
    meta: SaferpayCallbackrv35JkTo6zMeta || {},
    alias: SaferpayCallbackrv35JkTo6zMeta?.alias || [],
    redirect: SaferpayCallbackrv35JkTo6zMeta?.redirect,
    component: () => import("/usr/src/app/pages/checkout/SaferpayCallback.vue").then(m => m.default || m)
  },
  {
    name: SaferpayCallbackrv35JkTo6zMeta?.name ?? "checkout-redirect___fr",
    path: SaferpayCallbackrv35JkTo6zMeta?.path ?? "/fr/checkout/\\:kind/\\:orderNumber/\\:hash",
    meta: SaferpayCallbackrv35JkTo6zMeta || {},
    alias: SaferpayCallbackrv35JkTo6zMeta?.alias || [],
    redirect: SaferpayCallbackrv35JkTo6zMeta?.redirect,
    component: () => import("/usr/src/app/pages/checkout/SaferpayCallback.vue").then(m => m.default || m)
  },
  {
    name: SaferpayCallbackrv35JkTo6zMeta?.name ?? "checkout-redirect___en",
    path: SaferpayCallbackrv35JkTo6zMeta?.path ?? "/checkout/:kind/:orderNumber/:hash",
    meta: SaferpayCallbackrv35JkTo6zMeta || {},
    alias: SaferpayCallbackrv35JkTo6zMeta?.alias || [],
    redirect: SaferpayCallbackrv35JkTo6zMeta?.redirect,
    component: () => import("/usr/src/app/pages/checkout/SaferpayCallback.vue").then(m => m.default || m)
  },
  {
    name: SaferpayCallbackrv35JkTo6zMeta?.name ?? "checkout-redirect___de",
    path: SaferpayCallbackrv35JkTo6zMeta?.path ?? "/de/checkout/\\:kind/\\:orderNumber/\\:hash",
    meta: SaferpayCallbackrv35JkTo6zMeta || {},
    alias: SaferpayCallbackrv35JkTo6zMeta?.alias || [],
    redirect: SaferpayCallbackrv35JkTo6zMeta?.redirect,
    component: () => import("/usr/src/app/pages/checkout/SaferpayCallback.vue").then(m => m.default || m)
  },
  {
    name: SaferpayCallbackrv35JkTo6zMeta?.name ?? "checkout-redirect___fr",
    path: SaferpayCallbackrv35JkTo6zMeta?.path ?? "/fr/checkout/\\:kind/\\:orderNumber/\\:hash",
    meta: SaferpayCallbackrv35JkTo6zMeta || {},
    alias: SaferpayCallbackrv35JkTo6zMeta?.alias || [],
    redirect: SaferpayCallbackrv35JkTo6zMeta?.redirect,
    component: () => import("/usr/src/app/pages/checkout/SaferpayCallback.vue").then(m => m.default || m)
  },
  {
    name: SaferpayCallbackrv35JkTo6zMeta?.name ?? "checkout-redirect___en",
    path: SaferpayCallbackrv35JkTo6zMeta?.path ?? "/checkout/:kind/:orderNumber/:hash",
    meta: SaferpayCallbackrv35JkTo6zMeta || {},
    alias: SaferpayCallbackrv35JkTo6zMeta?.alias || [],
    redirect: SaferpayCallbackrv35JkTo6zMeta?.redirect,
    component: () => import("/usr/src/app/pages/checkout/SaferpayCallback.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93pvGVZEAWcBMeta?.name ?? "slug___de",
    path: _91_46_46_46slug_93pvGVZEAWcBMeta?.path ?? "/de/:slug(.*)*",
    meta: _91_46_46_46slug_93pvGVZEAWcBMeta || {},
    alias: _91_46_46_46slug_93pvGVZEAWcBMeta?.alias || [],
    redirect: _91_46_46_46slug_93pvGVZEAWcBMeta?.redirect,
    component: () => import("/usr/src/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93pvGVZEAWcBMeta?.name ?? "slug___fr",
    path: _91_46_46_46slug_93pvGVZEAWcBMeta?.path ?? "/fr/:slug(.*)*",
    meta: _91_46_46_46slug_93pvGVZEAWcBMeta || {},
    alias: _91_46_46_46slug_93pvGVZEAWcBMeta?.alias || [],
    redirect: _91_46_46_46slug_93pvGVZEAWcBMeta?.redirect,
    component: () => import("/usr/src/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93pvGVZEAWcBMeta?.name ?? "slug___en",
    path: _91_46_46_46slug_93pvGVZEAWcBMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93pvGVZEAWcBMeta || {},
    alias: _91_46_46_46slug_93pvGVZEAWcBMeta?.alias || [],
    redirect: _91_46_46_46slug_93pvGVZEAWcBMeta?.redirect,
    component: () => import("/usr/src/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: Addn3V91uhFr3Meta?.name ?? "account-address-Add___de",
    path: Addn3V91uhFr3Meta?.path ?? "/de/konto/addressen/neu",
    meta: Addn3V91uhFr3Meta || {},
    alias: Addn3V91uhFr3Meta?.alias || [],
    redirect: Addn3V91uhFr3Meta?.redirect,
    component: () => import("/usr/src/app/pages/account/address/Add.vue").then(m => m.default || m)
  },
  {
    name: Addn3V91uhFr3Meta?.name ?? "account-address-Add___fr",
    path: Addn3V91uhFr3Meta?.path ?? "/fr/compte/adresse/nouveau",
    meta: Addn3V91uhFr3Meta || {},
    alias: Addn3V91uhFr3Meta?.alias || [],
    redirect: Addn3V91uhFr3Meta?.redirect,
    component: () => import("/usr/src/app/pages/account/address/Add.vue").then(m => m.default || m)
  },
  {
    name: Addn3V91uhFr3Meta?.name ?? "account-address-Add___en",
    path: Addn3V91uhFr3Meta?.path ?? "/account/address/Add",
    meta: Addn3V91uhFr3Meta || {},
    alias: Addn3V91uhFr3Meta?.alias || [],
    redirect: Addn3V91uhFr3Meta?.redirect,
    component: () => import("/usr/src/app/pages/account/address/Add.vue").then(m => m.default || m)
  },
  {
    name: EditjwDUcx0xBgMeta?.name ?? "account-address-Edit___de",
    path: EditjwDUcx0xBgMeta?.path ?? "/de/konto/addressen/:id()",
    meta: EditjwDUcx0xBgMeta || {},
    alias: EditjwDUcx0xBgMeta?.alias || [],
    redirect: EditjwDUcx0xBgMeta?.redirect,
    component: () => import("/usr/src/app/pages/account/address/Edit.vue").then(m => m.default || m)
  },
  {
    name: EditjwDUcx0xBgMeta?.name ?? "account-address-Edit___fr",
    path: EditjwDUcx0xBgMeta?.path ?? "/fr/compte/adresse/editer/\\:id",
    meta: EditjwDUcx0xBgMeta || {},
    alias: EditjwDUcx0xBgMeta?.alias || [],
    redirect: EditjwDUcx0xBgMeta?.redirect,
    component: () => import("/usr/src/app/pages/account/address/Edit.vue").then(m => m.default || m)
  },
  {
    name: EditjwDUcx0xBgMeta?.name ?? "account-address-Edit___en",
    path: EditjwDUcx0xBgMeta?.path ?? "/account/address/Edit",
    meta: EditjwDUcx0xBgMeta || {},
    alias: EditjwDUcx0xBgMeta?.alias || [],
    redirect: EditjwDUcx0xBgMeta?.redirect,
    component: () => import("/usr/src/app/pages/account/address/Edit.vue").then(m => m.default || m)
  },
  {
    name: Overview8TBUh1sCglMeta?.name ?? "account-address-Overview___de",
    path: Overview8TBUh1sCglMeta?.path ?? "/de/konto/adressen",
    meta: Overview8TBUh1sCglMeta || {},
    alias: Overview8TBUh1sCglMeta?.alias || [],
    redirect: Overview8TBUh1sCglMeta?.redirect,
    component: () => import("/usr/src/app/pages/account/address/Overview.vue").then(m => m.default || m)
  },
  {
    name: Overview8TBUh1sCglMeta?.name ?? "account-address-Overview___fr",
    path: Overview8TBUh1sCglMeta?.path ?? "/fr/compte/adresse",
    meta: Overview8TBUh1sCglMeta || {},
    alias: Overview8TBUh1sCglMeta?.alias || [],
    redirect: Overview8TBUh1sCglMeta?.redirect,
    component: () => import("/usr/src/app/pages/account/address/Overview.vue").then(m => m.default || m)
  },
  {
    name: Overview8TBUh1sCglMeta?.name ?? "account-address-Overview___en",
    path: Overview8TBUh1sCglMeta?.path ?? "/account/address/Overview",
    meta: Overview8TBUh1sCglMeta || {},
    alias: Overview8TBUh1sCglMeta?.alias || [],
    redirect: Overview8TBUh1sCglMeta?.redirect,
    component: () => import("/usr/src/app/pages/account/address/Overview.vue").then(m => m.default || m)
  },
  {
    name: Confirmation0cnIFDuTnEMeta?.name ?? "account-email-Confirmation___de",
    path: Confirmation0cnIFDuTnEMeta?.path ?? "/de/account/email/Confirmation",
    meta: Confirmation0cnIFDuTnEMeta || {},
    alias: Confirmation0cnIFDuTnEMeta?.alias || [],
    redirect: Confirmation0cnIFDuTnEMeta?.redirect,
    component: () => import("/usr/src/app/pages/account/email/Confirmation.vue").then(m => m.default || m)
  },
  {
    name: Confirmation0cnIFDuTnEMeta?.name ?? "account-email-Confirmation___fr",
    path: Confirmation0cnIFDuTnEMeta?.path ?? "/fr/account/email/Confirmation",
    meta: Confirmation0cnIFDuTnEMeta || {},
    alias: Confirmation0cnIFDuTnEMeta?.alias || [],
    redirect: Confirmation0cnIFDuTnEMeta?.redirect,
    component: () => import("/usr/src/app/pages/account/email/Confirmation.vue").then(m => m.default || m)
  },
  {
    name: Confirmation0cnIFDuTnEMeta?.name ?? "account-email-Confirmation___en",
    path: Confirmation0cnIFDuTnEMeta?.path ?? "/account/email/Confirmation",
    meta: Confirmation0cnIFDuTnEMeta || {},
    alias: Confirmation0cnIFDuTnEMeta?.alias || [],
    redirect: Confirmation0cnIFDuTnEMeta?.redirect,
    component: () => import("/usr/src/app/pages/account/email/Confirmation.vue").then(m => m.default || m)
  },
  {
    name: ConfirmedLVYjLIDW9zMeta?.name ?? "account-email-Confirmed___de",
    path: ConfirmedLVYjLIDW9zMeta?.path ?? "/de/account/email/Confirmed",
    meta: ConfirmedLVYjLIDW9zMeta || {},
    alias: ConfirmedLVYjLIDW9zMeta?.alias || [],
    redirect: ConfirmedLVYjLIDW9zMeta?.redirect,
    component: () => import("/usr/src/app/pages/account/email/Confirmed.vue").then(m => m.default || m)
  },
  {
    name: ConfirmedLVYjLIDW9zMeta?.name ?? "account-email-Confirmed___fr",
    path: ConfirmedLVYjLIDW9zMeta?.path ?? "/fr/account/email/Confirmed",
    meta: ConfirmedLVYjLIDW9zMeta || {},
    alias: ConfirmedLVYjLIDW9zMeta?.alias || [],
    redirect: ConfirmedLVYjLIDW9zMeta?.redirect,
    component: () => import("/usr/src/app/pages/account/email/Confirmed.vue").then(m => m.default || m)
  },
  {
    name: ConfirmedLVYjLIDW9zMeta?.name ?? "account-email-Confirmed___en",
    path: ConfirmedLVYjLIDW9zMeta?.path ?? "/account/email/Confirmed",
    meta: ConfirmedLVYjLIDW9zMeta || {},
    alias: ConfirmedLVYjLIDW9zMeta?.alias || [],
    redirect: ConfirmedLVYjLIDW9zMeta?.redirect,
    component: () => import("/usr/src/app/pages/account/email/Confirmed.vue").then(m => m.default || m)
  },
  {
    name: SentYtgRGXsuanMeta?.name ?? "account-email-Sent___de",
    path: SentYtgRGXsuanMeta?.path ?? "/de/account/email/Sent",
    meta: SentYtgRGXsuanMeta || {},
    alias: SentYtgRGXsuanMeta?.alias || [],
    redirect: SentYtgRGXsuanMeta?.redirect,
    component: () => import("/usr/src/app/pages/account/email/Sent.vue").then(m => m.default || m)
  },
  {
    name: SentYtgRGXsuanMeta?.name ?? "account-email-Sent___fr",
    path: SentYtgRGXsuanMeta?.path ?? "/fr/account/email/Sent",
    meta: SentYtgRGXsuanMeta || {},
    alias: SentYtgRGXsuanMeta?.alias || [],
    redirect: SentYtgRGXsuanMeta?.redirect,
    component: () => import("/usr/src/app/pages/account/email/Sent.vue").then(m => m.default || m)
  },
  {
    name: SentYtgRGXsuanMeta?.name ?? "account-email-Sent___en",
    path: SentYtgRGXsuanMeta?.path ?? "/account/email/Sent",
    meta: SentYtgRGXsuanMeta || {},
    alias: SentYtgRGXsuanMeta?.alias || [],
    redirect: SentYtgRGXsuanMeta?.redirect,
    component: () => import("/usr/src/app/pages/account/email/Sent.vue").then(m => m.default || m)
  },
  {
    name: MemberXcd3K9Oac1Meta?.name ?? "account-Member___de",
    path: MemberXcd3K9Oac1Meta?.path ?? "/de/konto/mitglied",
    meta: MemberXcd3K9Oac1Meta || {},
    alias: MemberXcd3K9Oac1Meta?.alias || [],
    redirect: MemberXcd3K9Oac1Meta?.redirect,
    component: () => import("/usr/src/app/pages/account/Member.vue").then(m => m.default || m)
  },
  {
    name: MemberXcd3K9Oac1Meta?.name ?? "account-Member___fr",
    path: MemberXcd3K9Oac1Meta?.path ?? "/fr/compte/profil",
    meta: MemberXcd3K9Oac1Meta || {},
    alias: MemberXcd3K9Oac1Meta?.alias || [],
    redirect: MemberXcd3K9Oac1Meta?.redirect,
    component: () => import("/usr/src/app/pages/account/Member.vue").then(m => m.default || m)
  },
  {
    name: MemberXcd3K9Oac1Meta?.name ?? "account-Member___en",
    path: MemberXcd3K9Oac1Meta?.path ?? "/account/Member",
    meta: MemberXcd3K9Oac1Meta || {},
    alias: MemberXcd3K9Oac1Meta?.alias || [],
    redirect: MemberXcd3K9Oac1Meta?.redirect,
    component: () => import("/usr/src/app/pages/account/Member.vue").then(m => m.default || m)
  },
  {
    name: OrderDetail9a7txqcZJxMeta?.name ?? "account-OrderDetail___de",
    path: OrderDetail9a7txqcZJxMeta?.path ?? "/de/konto/bestellung/:id()",
    meta: OrderDetail9a7txqcZJxMeta || {},
    alias: OrderDetail9a7txqcZJxMeta?.alias || [],
    redirect: OrderDetail9a7txqcZJxMeta?.redirect,
    component: () => import("/usr/src/app/pages/account/OrderDetail.vue").then(m => m.default || m)
  },
  {
    name: OrderDetail9a7txqcZJxMeta?.name ?? "account-OrderDetail___fr",
    path: OrderDetail9a7txqcZJxMeta?.path ?? "/fr/compte/commande/:id()",
    meta: OrderDetail9a7txqcZJxMeta || {},
    alias: OrderDetail9a7txqcZJxMeta?.alias || [],
    redirect: OrderDetail9a7txqcZJxMeta?.redirect,
    component: () => import("/usr/src/app/pages/account/OrderDetail.vue").then(m => m.default || m)
  },
  {
    name: OrderDetail9a7txqcZJxMeta?.name ?? "account-OrderDetail___en",
    path: OrderDetail9a7txqcZJxMeta?.path ?? "/account/OrderDetail",
    meta: OrderDetail9a7txqcZJxMeta || {},
    alias: OrderDetail9a7txqcZJxMeta?.alias || [],
    redirect: OrderDetail9a7txqcZJxMeta?.redirect,
    component: () => import("/usr/src/app/pages/account/OrderDetail.vue").then(m => m.default || m)
  },
  {
    name: Overview0AwmiEJ9ISMeta?.name ?? "account-Overview___de",
    path: Overview0AwmiEJ9ISMeta?.path ?? "/de/konto",
    meta: Overview0AwmiEJ9ISMeta || {},
    alias: Overview0AwmiEJ9ISMeta?.alias || [],
    redirect: Overview0AwmiEJ9ISMeta?.redirect,
    component: () => import("/usr/src/app/pages/account/Overview.vue").then(m => m.default || m)
  },
  {
    name: Overview0AwmiEJ9ISMeta?.name ?? "account-Overview___fr",
    path: Overview0AwmiEJ9ISMeta?.path ?? "/fr/compte",
    meta: Overview0AwmiEJ9ISMeta || {},
    alias: Overview0AwmiEJ9ISMeta?.alias || [],
    redirect: Overview0AwmiEJ9ISMeta?.redirect,
    component: () => import("/usr/src/app/pages/account/Overview.vue").then(m => m.default || m)
  },
  {
    name: Overview0AwmiEJ9ISMeta?.name ?? "account-Overview___en",
    path: Overview0AwmiEJ9ISMeta?.path ?? "/account/Overview",
    meta: Overview0AwmiEJ9ISMeta || {},
    alias: Overview0AwmiEJ9ISMeta?.alias || [],
    redirect: Overview0AwmiEJ9ISMeta?.redirect,
    component: () => import("/usr/src/app/pages/account/Overview.vue").then(m => m.default || m)
  },
  {
    name: RegistrationpcyjU8O365Meta?.name ?? "account-Registration___de",
    path: RegistrationpcyjU8O365Meta?.path ?? "/de/account/Registration",
    meta: RegistrationpcyjU8O365Meta || {},
    alias: RegistrationpcyjU8O365Meta?.alias || [],
    redirect: RegistrationpcyjU8O365Meta?.redirect,
    component: () => import("/usr/src/app/pages/account/Registration.vue").then(m => m.default || m)
  },
  {
    name: RegistrationpcyjU8O365Meta?.name ?? "account-Registration___fr",
    path: RegistrationpcyjU8O365Meta?.path ?? "/fr/account/Registration",
    meta: RegistrationpcyjU8O365Meta || {},
    alias: RegistrationpcyjU8O365Meta?.alias || [],
    redirect: RegistrationpcyjU8O365Meta?.redirect,
    component: () => import("/usr/src/app/pages/account/Registration.vue").then(m => m.default || m)
  },
  {
    name: RegistrationpcyjU8O365Meta?.name ?? "account-Registration___en",
    path: RegistrationpcyjU8O365Meta?.path ?? "/account/Registration",
    meta: RegistrationpcyjU8O365Meta || {},
    alias: RegistrationpcyjU8O365Meta?.alias || [],
    redirect: RegistrationpcyjU8O365Meta?.redirect,
    component: () => import("/usr/src/app/pages/account/Registration.vue").then(m => m.default || m)
  },
  {
    name: base0lXVpfAHTSMeta?.name ?? "base___de",
    path: base0lXVpfAHTSMeta?.path ?? "/de/base",
    meta: base0lXVpfAHTSMeta || {},
    alias: base0lXVpfAHTSMeta?.alias || [],
    redirect: base0lXVpfAHTSMeta?.redirect,
    component: () => import("/usr/src/app/pages/base.vue").then(m => m.default || m)
  },
  {
    name: base0lXVpfAHTSMeta?.name ?? "base___fr",
    path: base0lXVpfAHTSMeta?.path ?? "/fr/base",
    meta: base0lXVpfAHTSMeta || {},
    alias: base0lXVpfAHTSMeta?.alias || [],
    redirect: base0lXVpfAHTSMeta?.redirect,
    component: () => import("/usr/src/app/pages/base.vue").then(m => m.default || m)
  },
  {
    name: base0lXVpfAHTSMeta?.name ?? "base___en",
    path: base0lXVpfAHTSMeta?.path ?? "/base",
    meta: base0lXVpfAHTSMeta || {},
    alias: base0lXVpfAHTSMeta?.alias || [],
    redirect: base0lXVpfAHTSMeta?.redirect,
    component: () => import("/usr/src/app/pages/base.vue").then(m => m.default || m)
  },
  {
    name: AddressZ7kpmHC7V0Meta?.name ?? "checkout-Address___de",
    path: AddressZ7kpmHC7V0Meta?.path ?? "/de/checkout/gast",
    meta: AddressZ7kpmHC7V0Meta || {},
    alias: AddressZ7kpmHC7V0Meta?.alias || [],
    redirect: AddressZ7kpmHC7V0Meta?.redirect,
    component: () => import("/usr/src/app/pages/checkout/Address.vue").then(m => m.default || m)
  },
  {
    name: AddressZ7kpmHC7V0Meta?.name ?? "checkout-Address___fr",
    path: AddressZ7kpmHC7V0Meta?.path ?? "/fr/sortie-de-caisse/invite",
    meta: AddressZ7kpmHC7V0Meta || {},
    alias: AddressZ7kpmHC7V0Meta?.alias || [],
    redirect: AddressZ7kpmHC7V0Meta?.redirect,
    component: () => import("/usr/src/app/pages/checkout/Address.vue").then(m => m.default || m)
  },
  {
    name: AddressZ7kpmHC7V0Meta?.name ?? "checkout-Address___en",
    path: AddressZ7kpmHC7V0Meta?.path ?? "/checkout/Address",
    meta: AddressZ7kpmHC7V0Meta || {},
    alias: AddressZ7kpmHC7V0Meta?.alias || [],
    redirect: AddressZ7kpmHC7V0Meta?.redirect,
    component: () => import("/usr/src/app/pages/checkout/Address.vue").then(m => m.default || m)
  },
  {
    name: PreLoginYBzpMz37jgMeta?.name ?? "checkout-PreLogin___de",
    path: PreLoginYBzpMz37jgMeta?.path ?? "/de/checkout",
    meta: PreLoginYBzpMz37jgMeta || {},
    alias: PreLoginYBzpMz37jgMeta?.alias || [],
    redirect: PreLoginYBzpMz37jgMeta?.redirect,
    component: () => import("/usr/src/app/pages/checkout/PreLogin.vue").then(m => m.default || m)
  },
  {
    name: PreLoginYBzpMz37jgMeta?.name ?? "checkout-PreLogin___fr",
    path: PreLoginYBzpMz37jgMeta?.path ?? "/fr/sortie-de-caisse",
    meta: PreLoginYBzpMz37jgMeta || {},
    alias: PreLoginYBzpMz37jgMeta?.alias || [],
    redirect: PreLoginYBzpMz37jgMeta?.redirect,
    component: () => import("/usr/src/app/pages/checkout/PreLogin.vue").then(m => m.default || m)
  },
  {
    name: PreLoginYBzpMz37jgMeta?.name ?? "checkout-PreLogin___en",
    path: PreLoginYBzpMz37jgMeta?.path ?? "/checkout/PreLogin",
    meta: PreLoginYBzpMz37jgMeta || {},
    alias: PreLoginYBzpMz37jgMeta?.alias || [],
    redirect: PreLoginYBzpMz37jgMeta?.redirect,
    component: () => import("/usr/src/app/pages/checkout/PreLogin.vue").then(m => m.default || m)
  },
  {
    name: SaferpayMyPYuhOK5TMeta?.name ?? "checkout-Saferpay___de",
    path: SaferpayMyPYuhOK5TMeta?.path ?? "/de/checkout/bezahlung",
    meta: SaferpayMyPYuhOK5TMeta || {},
    alias: SaferpayMyPYuhOK5TMeta?.alias || [],
    redirect: SaferpayMyPYuhOK5TMeta?.redirect,
    component: () => import("/usr/src/app/pages/checkout/Saferpay.vue").then(m => m.default || m)
  },
  {
    name: SaferpayMyPYuhOK5TMeta?.name ?? "checkout-Saferpay___fr",
    path: SaferpayMyPYuhOK5TMeta?.path ?? "/fr/sortie-de-caisse/paiement",
    meta: SaferpayMyPYuhOK5TMeta || {},
    alias: SaferpayMyPYuhOK5TMeta?.alias || [],
    redirect: SaferpayMyPYuhOK5TMeta?.redirect,
    component: () => import("/usr/src/app/pages/checkout/Saferpay.vue").then(m => m.default || m)
  },
  {
    name: SaferpayMyPYuhOK5TMeta?.name ?? "checkout-Saferpay___en",
    path: SaferpayMyPYuhOK5TMeta?.path ?? "/checkout/Saferpay",
    meta: SaferpayMyPYuhOK5TMeta || {},
    alias: SaferpayMyPYuhOK5TMeta?.alias || [],
    redirect: SaferpayMyPYuhOK5TMeta?.redirect,
    component: () => import("/usr/src/app/pages/checkout/Saferpay.vue").then(m => m.default || m)
  },
  {
    name: SaferpayCallbackrv35JkTo6zMeta?.name ?? "checkout-SaferpayCallback___de",
    path: SaferpayCallbackrv35JkTo6zMeta?.path ?? "/de/checkout/\\:kind/\\:orderNumber/\\:hash",
    meta: SaferpayCallbackrv35JkTo6zMeta || {},
    alias: SaferpayCallbackrv35JkTo6zMeta?.alias || [],
    redirect: SaferpayCallbackrv35JkTo6zMeta?.redirect,
    component: () => import("/usr/src/app/pages/checkout/SaferpayCallback.vue").then(m => m.default || m)
  },
  {
    name: SaferpayCallbackrv35JkTo6zMeta?.name ?? "checkout-SaferpayCallback___fr",
    path: SaferpayCallbackrv35JkTo6zMeta?.path ?? "/fr/checkout/\\:kind/\\:orderNumber/\\:hash",
    meta: SaferpayCallbackrv35JkTo6zMeta || {},
    alias: SaferpayCallbackrv35JkTo6zMeta?.alias || [],
    redirect: SaferpayCallbackrv35JkTo6zMeta?.redirect,
    component: () => import("/usr/src/app/pages/checkout/SaferpayCallback.vue").then(m => m.default || m)
  },
  {
    name: SaferpayCallbackrv35JkTo6zMeta?.name ?? "checkout-SaferpayCallback___en",
    path: SaferpayCallbackrv35JkTo6zMeta?.path ?? "/checkout/SaferpayCallback",
    meta: SaferpayCallbackrv35JkTo6zMeta || {},
    alias: SaferpayCallbackrv35JkTo6zMeta?.alias || [],
    redirect: SaferpayCallbackrv35JkTo6zMeta?.redirect,
    component: () => import("/usr/src/app/pages/checkout/SaferpayCallback.vue").then(m => m.default || m)
  },
  {
    name: Shipping1oZZnAgsVaMeta?.name ?? "checkout-Shipping___de",
    path: Shipping1oZZnAgsVaMeta?.path ?? "/de/checkout/versand",
    meta: Shipping1oZZnAgsVaMeta || {},
    alias: Shipping1oZZnAgsVaMeta?.alias || [],
    redirect: Shipping1oZZnAgsVaMeta?.redirect,
    component: () => import("/usr/src/app/pages/checkout/Shipping.vue").then(m => m.default || m)
  },
  {
    name: Shipping1oZZnAgsVaMeta?.name ?? "checkout-Shipping___fr",
    path: Shipping1oZZnAgsVaMeta?.path ?? "/fr/sortie-de-caisse/expedition",
    meta: Shipping1oZZnAgsVaMeta || {},
    alias: Shipping1oZZnAgsVaMeta?.alias || [],
    redirect: Shipping1oZZnAgsVaMeta?.redirect,
    component: () => import("/usr/src/app/pages/checkout/Shipping.vue").then(m => m.default || m)
  },
  {
    name: Shipping1oZZnAgsVaMeta?.name ?? "checkout-Shipping___en",
    path: Shipping1oZZnAgsVaMeta?.path ?? "/checkout/Shipping",
    meta: Shipping1oZZnAgsVaMeta || {},
    alias: Shipping1oZZnAgsVaMeta?.alias || [],
    redirect: Shipping1oZZnAgsVaMeta?.redirect,
    component: () => import("/usr/src/app/pages/checkout/Shipping.vue").then(m => m.default || m)
  },
  {
    name: SummaryDoRytxW7t7Meta?.name ?? "checkout-Summary___de",
    path: SummaryDoRytxW7t7Meta?.path ?? "/de/checkout/ubersicht",
    meta: SummaryDoRytxW7t7Meta || {},
    alias: SummaryDoRytxW7t7Meta?.alias || [],
    redirect: SummaryDoRytxW7t7Meta?.redirect,
    component: () => import("/usr/src/app/pages/checkout/Summary.vue").then(m => m.default || m)
  },
  {
    name: SummaryDoRytxW7t7Meta?.name ?? "checkout-Summary___fr",
    path: SummaryDoRytxW7t7Meta?.path ?? "/fr/sortie-de-caisse/resume",
    meta: SummaryDoRytxW7t7Meta || {},
    alias: SummaryDoRytxW7t7Meta?.alias || [],
    redirect: SummaryDoRytxW7t7Meta?.redirect,
    component: () => import("/usr/src/app/pages/checkout/Summary.vue").then(m => m.default || m)
  },
  {
    name: SummaryDoRytxW7t7Meta?.name ?? "checkout-Summary___en",
    path: SummaryDoRytxW7t7Meta?.path ?? "/checkout/Summary",
    meta: SummaryDoRytxW7t7Meta || {},
    alias: SummaryDoRytxW7t7Meta?.alias || [],
    redirect: SummaryDoRytxW7t7Meta?.redirect,
    component: () => import("/usr/src/app/pages/checkout/Summary.vue").then(m => m.default || m)
  },
  {
    name: ThankYouMO5fsgDbFTMeta?.name ?? "checkout-ThankYou___de",
    path: ThankYouMO5fsgDbFTMeta?.path ?? "/de/checkout/danke",
    meta: ThankYouMO5fsgDbFTMeta || {},
    alias: ThankYouMO5fsgDbFTMeta?.alias || [],
    redirect: ThankYouMO5fsgDbFTMeta?.redirect,
    component: () => import("/usr/src/app/pages/checkout/ThankYou.vue").then(m => m.default || m)
  },
  {
    name: ThankYouMO5fsgDbFTMeta?.name ?? "checkout-ThankYou___fr",
    path: ThankYouMO5fsgDbFTMeta?.path ?? "/fr/sortie-de-caisse/merci",
    meta: ThankYouMO5fsgDbFTMeta || {},
    alias: ThankYouMO5fsgDbFTMeta?.alias || [],
    redirect: ThankYouMO5fsgDbFTMeta?.redirect,
    component: () => import("/usr/src/app/pages/checkout/ThankYou.vue").then(m => m.default || m)
  },
  {
    name: ThankYouMO5fsgDbFTMeta?.name ?? "checkout-ThankYou___en",
    path: ThankYouMO5fsgDbFTMeta?.path ?? "/checkout/ThankYou",
    meta: ThankYouMO5fsgDbFTMeta || {},
    alias: ThankYouMO5fsgDbFTMeta?.alias || [],
    redirect: ThankYouMO5fsgDbFTMeta?.redirect,
    component: () => import("/usr/src/app/pages/checkout/ThankYou.vue").then(m => m.default || m)
  },
  {
    name: VideoEAW5P29eWWMeta?.name ?? "cms-Video___de",
    path: VideoEAW5P29eWWMeta?.path ?? "/de/cms/Video",
    meta: VideoEAW5P29eWWMeta || {},
    alias: VideoEAW5P29eWWMeta?.alias || [],
    redirect: VideoEAW5P29eWWMeta?.redirect,
    component: () => import("/usr/src/app/pages/cms/Video.vue").then(m => m.default || m)
  },
  {
    name: VideoEAW5P29eWWMeta?.name ?? "cms-Video___fr",
    path: VideoEAW5P29eWWMeta?.path ?? "/fr/cms/Video",
    meta: VideoEAW5P29eWWMeta || {},
    alias: VideoEAW5P29eWWMeta?.alias || [],
    redirect: VideoEAW5P29eWWMeta?.redirect,
    component: () => import("/usr/src/app/pages/cms/Video.vue").then(m => m.default || m)
  },
  {
    name: VideoEAW5P29eWWMeta?.name ?? "cms-Video___en",
    path: VideoEAW5P29eWWMeta?.path ?? "/cms/Video",
    meta: VideoEAW5P29eWWMeta || {},
    alias: VideoEAW5P29eWWMeta?.alias || [],
    redirect: VideoEAW5P29eWWMeta?.redirect,
    component: () => import("/usr/src/app/pages/cms/Video.vue").then(m => m.default || m)
  },
  {
    name: Courselq9MI9RVOMMeta?.name ?? "course-Course___de",
    path: Courselq9MI9RVOMMeta?.path ?? "/de/course/Course",
    meta: Courselq9MI9RVOMMeta || {},
    alias: Courselq9MI9RVOMMeta?.alias || [],
    redirect: Courselq9MI9RVOMMeta?.redirect,
    component: () => import("/usr/src/app/pages/course/Course.vue").then(m => m.default || m)
  },
  {
    name: Courselq9MI9RVOMMeta?.name ?? "course-Course___fr",
    path: Courselq9MI9RVOMMeta?.path ?? "/fr/course/Course",
    meta: Courselq9MI9RVOMMeta || {},
    alias: Courselq9MI9RVOMMeta?.alias || [],
    redirect: Courselq9MI9RVOMMeta?.redirect,
    component: () => import("/usr/src/app/pages/course/Course.vue").then(m => m.default || m)
  },
  {
    name: Courselq9MI9RVOMMeta?.name ?? "course-Course___en",
    path: Courselq9MI9RVOMMeta?.path ?? "/course/Course",
    meta: Courselq9MI9RVOMMeta || {},
    alias: Courselq9MI9RVOMMeta?.alias || [],
    redirect: Courselq9MI9RVOMMeta?.redirect,
    component: () => import("/usr/src/app/pages/course/Course.vue").then(m => m.default || m)
  },
  {
    name: Overview8954YDfq2uMeta?.name ?? "course-Overview___de",
    path: Overview8954YDfq2uMeta?.path ?? "/de/course/Overview",
    meta: Overview8954YDfq2uMeta || {},
    alias: Overview8954YDfq2uMeta?.alias || [],
    redirect: Overview8954YDfq2uMeta?.redirect,
    component: () => import("/usr/src/app/pages/course/Overview.vue").then(m => m.default || m)
  },
  {
    name: Overview8954YDfq2uMeta?.name ?? "course-Overview___fr",
    path: Overview8954YDfq2uMeta?.path ?? "/fr/course/Overview",
    meta: Overview8954YDfq2uMeta || {},
    alias: Overview8954YDfq2uMeta?.alias || [],
    redirect: Overview8954YDfq2uMeta?.redirect,
    component: () => import("/usr/src/app/pages/course/Overview.vue").then(m => m.default || m)
  },
  {
    name: Overview8954YDfq2uMeta?.name ?? "course-Overview___en",
    path: Overview8954YDfq2uMeta?.path ?? "/course/Overview",
    meta: Overview8954YDfq2uMeta || {},
    alias: Overview8954YDfq2uMeta?.alias || [],
    redirect: Overview8954YDfq2uMeta?.redirect,
    component: () => import("/usr/src/app/pages/course/Overview.vue").then(m => m.default || m)
  },
  {
    name: ErrorTC6LngnbbrMeta?.name ?? "error-Error___de",
    path: ErrorTC6LngnbbrMeta?.path ?? "/de/error/Error",
    meta: ErrorTC6LngnbbrMeta || {},
    alias: ErrorTC6LngnbbrMeta?.alias || [],
    redirect: ErrorTC6LngnbbrMeta?.redirect,
    component: () => import("/usr/src/app/pages/error/Error.vue").then(m => m.default || m)
  },
  {
    name: ErrorTC6LngnbbrMeta?.name ?? "error-Error___fr",
    path: ErrorTC6LngnbbrMeta?.path ?? "/fr/error/Error",
    meta: ErrorTC6LngnbbrMeta || {},
    alias: ErrorTC6LngnbbrMeta?.alias || [],
    redirect: ErrorTC6LngnbbrMeta?.redirect,
    component: () => import("/usr/src/app/pages/error/Error.vue").then(m => m.default || m)
  },
  {
    name: ErrorTC6LngnbbrMeta?.name ?? "error-Error___en",
    path: ErrorTC6LngnbbrMeta?.path ?? "/error/Error",
    meta: ErrorTC6LngnbbrMeta || {},
    alias: ErrorTC6LngnbbrMeta?.alias || [],
    redirect: ErrorTC6LngnbbrMeta?.redirect,
    component: () => import("/usr/src/app/pages/error/Error.vue").then(m => m.default || m)
  },
  {
    name: Overview99BFKsBzheMeta?.name ?? "job-Overview___de",
    path: Overview99BFKsBzheMeta?.path ?? "/de/job/Overview",
    meta: Overview99BFKsBzheMeta || {},
    alias: Overview99BFKsBzheMeta?.alias || [],
    redirect: Overview99BFKsBzheMeta?.redirect,
    component: () => import("/usr/src/app/pages/job/Overview.vue").then(m => m.default || m)
  },
  {
    name: Overview99BFKsBzheMeta?.name ?? "job-Overview___fr",
    path: Overview99BFKsBzheMeta?.path ?? "/fr/job/Overview",
    meta: Overview99BFKsBzheMeta || {},
    alias: Overview99BFKsBzheMeta?.alias || [],
    redirect: Overview99BFKsBzheMeta?.redirect,
    component: () => import("/usr/src/app/pages/job/Overview.vue").then(m => m.default || m)
  },
  {
    name: Overview99BFKsBzheMeta?.name ?? "job-Overview___en",
    path: Overview99BFKsBzheMeta?.path ?? "/job/Overview",
    meta: Overview99BFKsBzheMeta || {},
    alias: Overview99BFKsBzheMeta?.alias || [],
    redirect: Overview99BFKsBzheMeta?.redirect,
    component: () => import("/usr/src/app/pages/job/Overview.vue").then(m => m.default || m)
  },
  {
    name: PostinguEk4zc8CMvMeta?.name ?? "job-Posting___de",
    path: PostinguEk4zc8CMvMeta?.path ?? "/de/job/Posting",
    meta: PostinguEk4zc8CMvMeta || {},
    alias: PostinguEk4zc8CMvMeta?.alias || [],
    redirect: PostinguEk4zc8CMvMeta?.redirect,
    component: () => import("/usr/src/app/pages/job/Posting.vue").then(m => m.default || m)
  },
  {
    name: PostinguEk4zc8CMvMeta?.name ?? "job-Posting___fr",
    path: PostinguEk4zc8CMvMeta?.path ?? "/fr/job/Posting",
    meta: PostinguEk4zc8CMvMeta || {},
    alias: PostinguEk4zc8CMvMeta?.alias || [],
    redirect: PostinguEk4zc8CMvMeta?.redirect,
    component: () => import("/usr/src/app/pages/job/Posting.vue").then(m => m.default || m)
  },
  {
    name: PostinguEk4zc8CMvMeta?.name ?? "job-Posting___en",
    path: PostinguEk4zc8CMvMeta?.path ?? "/job/Posting",
    meta: PostinguEk4zc8CMvMeta || {},
    alias: PostinguEk4zc8CMvMeta?.alias || [],
    redirect: PostinguEk4zc8CMvMeta?.redirect,
    component: () => import("/usr/src/app/pages/job/Posting.vue").then(m => m.default || m)
  },
  {
    name: ImpressumNnwJOCDdREMeta?.name ?? "legal-Impressum___de",
    path: ImpressumNnwJOCDdREMeta?.path ?? "/de/legal/Impressum",
    meta: ImpressumNnwJOCDdREMeta || {},
    alias: ImpressumNnwJOCDdREMeta?.alias || [],
    redirect: ImpressumNnwJOCDdREMeta?.redirect,
    component: () => import("/usr/src/app/pages/legal/Impressum.vue").then(m => m.default || m)
  },
  {
    name: ImpressumNnwJOCDdREMeta?.name ?? "legal-Impressum___fr",
    path: ImpressumNnwJOCDdREMeta?.path ?? "/fr/legal/Impressum",
    meta: ImpressumNnwJOCDdREMeta || {},
    alias: ImpressumNnwJOCDdREMeta?.alias || [],
    redirect: ImpressumNnwJOCDdREMeta?.redirect,
    component: () => import("/usr/src/app/pages/legal/Impressum.vue").then(m => m.default || m)
  },
  {
    name: ImpressumNnwJOCDdREMeta?.name ?? "legal-Impressum___en",
    path: ImpressumNnwJOCDdREMeta?.path ?? "/legal/Impressum",
    meta: ImpressumNnwJOCDdREMeta || {},
    alias: ImpressumNnwJOCDdREMeta?.alias || [],
    redirect: ImpressumNnwJOCDdREMeta?.redirect,
    component: () => import("/usr/src/app/pages/legal/Impressum.vue").then(m => m.default || m)
  },
  {
    name: Detailw3w4d3JBm9Meta?.name ?? "location-Detail___de",
    path: Detailw3w4d3JBm9Meta?.path ?? "/de/location/Detail",
    meta: Detailw3w4d3JBm9Meta || {},
    alias: Detailw3w4d3JBm9Meta?.alias || [],
    redirect: Detailw3w4d3JBm9Meta?.redirect,
    component: () => import("/usr/src/app/pages/location/Detail.vue").then(m => m.default || m)
  },
  {
    name: Detailw3w4d3JBm9Meta?.name ?? "location-Detail___fr",
    path: Detailw3w4d3JBm9Meta?.path ?? "/fr/location/Detail",
    meta: Detailw3w4d3JBm9Meta || {},
    alias: Detailw3w4d3JBm9Meta?.alias || [],
    redirect: Detailw3w4d3JBm9Meta?.redirect,
    component: () => import("/usr/src/app/pages/location/Detail.vue").then(m => m.default || m)
  },
  {
    name: Detailw3w4d3JBm9Meta?.name ?? "location-Detail___en",
    path: Detailw3w4d3JBm9Meta?.path ?? "/location/Detail",
    meta: Detailw3w4d3JBm9Meta || {},
    alias: Detailw3w4d3JBm9Meta?.alias || [],
    redirect: Detailw3w4d3JBm9Meta?.redirect,
    component: () => import("/usr/src/app/pages/location/Detail.vue").then(m => m.default || m)
  },
  {
    name: Overvieww21Ce8BfMRMeta?.name ?? "location-Overview___de",
    path: Overvieww21Ce8BfMRMeta?.path ?? "/de/location/Overview",
    meta: Overvieww21Ce8BfMRMeta || {},
    alias: Overvieww21Ce8BfMRMeta?.alias || [],
    redirect: Overvieww21Ce8BfMRMeta?.redirect,
    component: () => import("/usr/src/app/pages/location/Overview.vue").then(m => m.default || m)
  },
  {
    name: Overvieww21Ce8BfMRMeta?.name ?? "location-Overview___fr",
    path: Overvieww21Ce8BfMRMeta?.path ?? "/fr/location/Overview",
    meta: Overvieww21Ce8BfMRMeta || {},
    alias: Overvieww21Ce8BfMRMeta?.alias || [],
    redirect: Overvieww21Ce8BfMRMeta?.redirect,
    component: () => import("/usr/src/app/pages/location/Overview.vue").then(m => m.default || m)
  },
  {
    name: Overvieww21Ce8BfMRMeta?.name ?? "location-Overview___en",
    path: Overvieww21Ce8BfMRMeta?.path ?? "/location/Overview",
    meta: Overvieww21Ce8BfMRMeta || {},
    alias: Overvieww21Ce8BfMRMeta?.alias || [],
    redirect: Overvieww21Ce8BfMRMeta?.redirect,
    component: () => import("/usr/src/app/pages/location/Overview.vue").then(m => m.default || m)
  },
  {
    name: CategoryVF8sPdAoELMeta?.name ?? "magazine-Category___de",
    path: CategoryVF8sPdAoELMeta?.path ?? "/de/magazine/Category",
    meta: CategoryVF8sPdAoELMeta || {},
    alias: CategoryVF8sPdAoELMeta?.alias || [],
    redirect: CategoryVF8sPdAoELMeta?.redirect,
    component: () => import("/usr/src/app/pages/magazine/Category.vue").then(m => m.default || m)
  },
  {
    name: CategoryVF8sPdAoELMeta?.name ?? "magazine-Category___fr",
    path: CategoryVF8sPdAoELMeta?.path ?? "/fr/magazine/Category",
    meta: CategoryVF8sPdAoELMeta || {},
    alias: CategoryVF8sPdAoELMeta?.alias || [],
    redirect: CategoryVF8sPdAoELMeta?.redirect,
    component: () => import("/usr/src/app/pages/magazine/Category.vue").then(m => m.default || m)
  },
  {
    name: CategoryVF8sPdAoELMeta?.name ?? "magazine-Category___en",
    path: CategoryVF8sPdAoELMeta?.path ?? "/magazine/Category",
    meta: CategoryVF8sPdAoELMeta || {},
    alias: CategoryVF8sPdAoELMeta?.alias || [],
    redirect: CategoryVF8sPdAoELMeta?.redirect,
    component: () => import("/usr/src/app/pages/magazine/Category.vue").then(m => m.default || m)
  },
  {
    name: Item8Iah1Eka2kMeta?.name ?? "magazine-Item___de",
    path: Item8Iah1Eka2kMeta?.path ?? "/de/magazine/Item",
    meta: Item8Iah1Eka2kMeta || {},
    alias: Item8Iah1Eka2kMeta?.alias || [],
    redirect: Item8Iah1Eka2kMeta?.redirect,
    component: () => import("/usr/src/app/pages/magazine/Item.vue").then(m => m.default || m)
  },
  {
    name: Item8Iah1Eka2kMeta?.name ?? "magazine-Item___fr",
    path: Item8Iah1Eka2kMeta?.path ?? "/fr/magazine/Item",
    meta: Item8Iah1Eka2kMeta || {},
    alias: Item8Iah1Eka2kMeta?.alias || [],
    redirect: Item8Iah1Eka2kMeta?.redirect,
    component: () => import("/usr/src/app/pages/magazine/Item.vue").then(m => m.default || m)
  },
  {
    name: Item8Iah1Eka2kMeta?.name ?? "magazine-Item___en",
    path: Item8Iah1Eka2kMeta?.path ?? "/magazine/Item",
    meta: Item8Iah1Eka2kMeta || {},
    alias: Item8Iah1Eka2kMeta?.alias || [],
    redirect: Item8Iah1Eka2kMeta?.redirect,
    component: () => import("/usr/src/app/pages/magazine/Item.vue").then(m => m.default || m)
  },
  {
    name: OverviewpW6kc9mZL5Meta?.name ?? "magazine-Overview___de",
    path: OverviewpW6kc9mZL5Meta?.path ?? "/de/magazine/Overview",
    meta: OverviewpW6kc9mZL5Meta || {},
    alias: OverviewpW6kc9mZL5Meta?.alias || [],
    redirect: OverviewpW6kc9mZL5Meta?.redirect,
    component: () => import("/usr/src/app/pages/magazine/Overview.vue").then(m => m.default || m)
  },
  {
    name: OverviewpW6kc9mZL5Meta?.name ?? "magazine-Overview___fr",
    path: OverviewpW6kc9mZL5Meta?.path ?? "/fr/magazine/Overview",
    meta: OverviewpW6kc9mZL5Meta || {},
    alias: OverviewpW6kc9mZL5Meta?.alias || [],
    redirect: OverviewpW6kc9mZL5Meta?.redirect,
    component: () => import("/usr/src/app/pages/magazine/Overview.vue").then(m => m.default || m)
  },
  {
    name: OverviewpW6kc9mZL5Meta?.name ?? "magazine-Overview___en",
    path: OverviewpW6kc9mZL5Meta?.path ?? "/magazine/Overview",
    meta: OverviewpW6kc9mZL5Meta || {},
    alias: OverviewpW6kc9mZL5Meta?.alias || [],
    redirect: OverviewpW6kc9mZL5Meta?.redirect,
    component: () => import("/usr/src/app/pages/magazine/Overview.vue").then(m => m.default || m)
  },
  {
    name: OutdatedXfMPF1TuokMeta?.name ?? "Outdated___de",
    path: OutdatedXfMPF1TuokMeta?.path ?? "/de/Outdated",
    meta: OutdatedXfMPF1TuokMeta || {},
    alias: OutdatedXfMPF1TuokMeta?.alias || [],
    redirect: OutdatedXfMPF1TuokMeta?.redirect,
    component: () => import("/usr/src/app/pages/Outdated.vue").then(m => m.default || m)
  },
  {
    name: OutdatedXfMPF1TuokMeta?.name ?? "Outdated___fr",
    path: OutdatedXfMPF1TuokMeta?.path ?? "/fr/Outdated",
    meta: OutdatedXfMPF1TuokMeta || {},
    alias: OutdatedXfMPF1TuokMeta?.alias || [],
    redirect: OutdatedXfMPF1TuokMeta?.redirect,
    component: () => import("/usr/src/app/pages/Outdated.vue").then(m => m.default || m)
  },
  {
    name: OutdatedXfMPF1TuokMeta?.name ?? "Outdated___en",
    path: OutdatedXfMPF1TuokMeta?.path ?? "/Outdated",
    meta: OutdatedXfMPF1TuokMeta || {},
    alias: OutdatedXfMPF1TuokMeta?.alias || [],
    redirect: OutdatedXfMPF1TuokMeta?.redirect,
    component: () => import("/usr/src/app/pages/Outdated.vue").then(m => m.default || m)
  },
  {
    name: previewRGYKYr0i5AMeta?.name ?? "preview___de",
    path: previewRGYKYr0i5AMeta?.path ?? "/de/preview",
    meta: previewRGYKYr0i5AMeta || {},
    alias: previewRGYKYr0i5AMeta?.alias || [],
    redirect: previewRGYKYr0i5AMeta?.redirect,
    component: () => import("/usr/src/app/pages/preview.vue").then(m => m.default || m)
  },
  {
    name: previewRGYKYr0i5AMeta?.name ?? "preview___fr",
    path: previewRGYKYr0i5AMeta?.path ?? "/fr/preview",
    meta: previewRGYKYr0i5AMeta || {},
    alias: previewRGYKYr0i5AMeta?.alias || [],
    redirect: previewRGYKYr0i5AMeta?.redirect,
    component: () => import("/usr/src/app/pages/preview.vue").then(m => m.default || m)
  },
  {
    name: previewRGYKYr0i5AMeta?.name ?? "preview___en",
    path: previewRGYKYr0i5AMeta?.path ?? "/preview",
    meta: previewRGYKYr0i5AMeta || {},
    alias: previewRGYKYr0i5AMeta?.alias || [],
    redirect: previewRGYKYr0i5AMeta?.redirect,
    component: () => import("/usr/src/app/pages/preview.vue").then(m => m.default || m)
  },
  {
    name: Catalogueh4o6Dl8fQPMeta?.name ?? "product-Catalogue___de",
    path: Catalogueh4o6Dl8fQPMeta?.path ?? "/de/product/Catalogue",
    meta: Catalogueh4o6Dl8fQPMeta || {},
    alias: Catalogueh4o6Dl8fQPMeta?.alias || [],
    redirect: Catalogueh4o6Dl8fQPMeta?.redirect,
    component: () => import("/usr/src/app/pages/product/Catalogue.vue").then(m => m.default || m)
  },
  {
    name: Catalogueh4o6Dl8fQPMeta?.name ?? "product-Catalogue___fr",
    path: Catalogueh4o6Dl8fQPMeta?.path ?? "/fr/product/Catalogue",
    meta: Catalogueh4o6Dl8fQPMeta || {},
    alias: Catalogueh4o6Dl8fQPMeta?.alias || [],
    redirect: Catalogueh4o6Dl8fQPMeta?.redirect,
    component: () => import("/usr/src/app/pages/product/Catalogue.vue").then(m => m.default || m)
  },
  {
    name: Catalogueh4o6Dl8fQPMeta?.name ?? "product-Catalogue___en",
    path: Catalogueh4o6Dl8fQPMeta?.path ?? "/product/Catalogue",
    meta: Catalogueh4o6Dl8fQPMeta || {},
    alias: Catalogueh4o6Dl8fQPMeta?.alias || [],
    redirect: Catalogueh4o6Dl8fQPMeta?.redirect,
    component: () => import("/usr/src/app/pages/product/Catalogue.vue").then(m => m.default || m)
  },
  {
    name: CategoryZIEegLiFDBMeta?.name ?? "product-Category___de",
    path: CategoryZIEegLiFDBMeta?.path ?? "/de/product/Category",
    meta: CategoryZIEegLiFDBMeta || {},
    alias: CategoryZIEegLiFDBMeta?.alias || [],
    redirect: CategoryZIEegLiFDBMeta?.redirect,
    component: () => import("/usr/src/app/pages/product/Category.vue").then(m => m.default || m)
  },
  {
    name: CategoryZIEegLiFDBMeta?.name ?? "product-Category___fr",
    path: CategoryZIEegLiFDBMeta?.path ?? "/fr/product/Category",
    meta: CategoryZIEegLiFDBMeta || {},
    alias: CategoryZIEegLiFDBMeta?.alias || [],
    redirect: CategoryZIEegLiFDBMeta?.redirect,
    component: () => import("/usr/src/app/pages/product/Category.vue").then(m => m.default || m)
  },
  {
    name: CategoryZIEegLiFDBMeta?.name ?? "product-Category___en",
    path: CategoryZIEegLiFDBMeta?.path ?? "/product/Category",
    meta: CategoryZIEegLiFDBMeta || {},
    alias: CategoryZIEegLiFDBMeta?.alias || [],
    redirect: CategoryZIEegLiFDBMeta?.redirect,
    component: () => import("/usr/src/app/pages/product/Category.vue").then(m => m.default || m)
  },
  {
    name: Detail6bfdkvOoNDMeta?.name ?? "product-Detail___de",
    path: Detail6bfdkvOoNDMeta?.path ?? "/de/product/Detail",
    meta: Detail6bfdkvOoNDMeta || {},
    alias: Detail6bfdkvOoNDMeta?.alias || [],
    redirect: Detail6bfdkvOoNDMeta?.redirect,
    component: () => import("/usr/src/app/pages/product/Detail.vue").then(m => m.default || m)
  },
  {
    name: Detail6bfdkvOoNDMeta?.name ?? "product-Detail___fr",
    path: Detail6bfdkvOoNDMeta?.path ?? "/fr/product/Detail",
    meta: Detail6bfdkvOoNDMeta || {},
    alias: Detail6bfdkvOoNDMeta?.alias || [],
    redirect: Detail6bfdkvOoNDMeta?.redirect,
    component: () => import("/usr/src/app/pages/product/Detail.vue").then(m => m.default || m)
  },
  {
    name: Detail6bfdkvOoNDMeta?.name ?? "product-Detail___en",
    path: Detail6bfdkvOoNDMeta?.path ?? "/product/Detail",
    meta: Detail6bfdkvOoNDMeta || {},
    alias: Detail6bfdkvOoNDMeta?.alias || [],
    redirect: Detail6bfdkvOoNDMeta?.redirect,
    component: () => import("/usr/src/app/pages/product/Detail.vue").then(m => m.default || m)
  },
  {
    name: SearchU77M4RgEh5Meta?.name ?? "search-Search___de",
    path: SearchU77M4RgEh5Meta?.path ?? "/de/suche",
    meta: SearchU77M4RgEh5Meta || {},
    alias: SearchU77M4RgEh5Meta?.alias || [],
    redirect: SearchU77M4RgEh5Meta?.redirect,
    component: () => import("/usr/src/app/pages/search/Search.vue").then(m => m.default || m)
  },
  {
    name: SearchU77M4RgEh5Meta?.name ?? "search-Search___fr",
    path: SearchU77M4RgEh5Meta?.path ?? "/fr/recherche",
    meta: SearchU77M4RgEh5Meta || {},
    alias: SearchU77M4RgEh5Meta?.alias || [],
    redirect: SearchU77M4RgEh5Meta?.redirect,
    component: () => import("/usr/src/app/pages/search/Search.vue").then(m => m.default || m)
  },
  {
    name: SearchU77M4RgEh5Meta?.name ?? "search-Search___en",
    path: SearchU77M4RgEh5Meta?.path ?? "/search/Search",
    meta: SearchU77M4RgEh5Meta || {},
    alias: SearchU77M4RgEh5Meta?.alias || [],
    redirect: SearchU77M4RgEh5Meta?.redirect,
    component: () => import("/usr/src/app/pages/search/Search.vue").then(m => m.default || m)
  },
  {
    name: EmployeekMEogTDkTLMeta?.name ?? "team-Employee___de",
    path: EmployeekMEogTDkTLMeta?.path ?? "/de/team/Employee",
    meta: EmployeekMEogTDkTLMeta || {},
    alias: EmployeekMEogTDkTLMeta?.alias || [],
    redirect: EmployeekMEogTDkTLMeta?.redirect,
    component: () => import("/usr/src/app/pages/team/Employee.vue").then(m => m.default || m)
  },
  {
    name: EmployeekMEogTDkTLMeta?.name ?? "team-Employee___fr",
    path: EmployeekMEogTDkTLMeta?.path ?? "/fr/team/Employee",
    meta: EmployeekMEogTDkTLMeta || {},
    alias: EmployeekMEogTDkTLMeta?.alias || [],
    redirect: EmployeekMEogTDkTLMeta?.redirect,
    component: () => import("/usr/src/app/pages/team/Employee.vue").then(m => m.default || m)
  },
  {
    name: EmployeekMEogTDkTLMeta?.name ?? "team-Employee___en",
    path: EmployeekMEogTDkTLMeta?.path ?? "/team/Employee",
    meta: EmployeekMEogTDkTLMeta || {},
    alias: EmployeekMEogTDkTLMeta?.alias || [],
    redirect: EmployeekMEogTDkTLMeta?.redirect,
    component: () => import("/usr/src/app/pages/team/Employee.vue").then(m => m.default || m)
  },
  {
    name: Team8jhszmPOJVMeta?.name ?? "team-Team___de",
    path: Team8jhszmPOJVMeta?.path ?? "/de/team/Team",
    meta: Team8jhszmPOJVMeta || {},
    alias: Team8jhszmPOJVMeta?.alias || [],
    redirect: Team8jhszmPOJVMeta?.redirect,
    component: () => import("/usr/src/app/pages/team/Team.vue").then(m => m.default || m)
  },
  {
    name: Team8jhszmPOJVMeta?.name ?? "team-Team___fr",
    path: Team8jhszmPOJVMeta?.path ?? "/fr/team/Team",
    meta: Team8jhszmPOJVMeta || {},
    alias: Team8jhszmPOJVMeta?.alias || [],
    redirect: Team8jhszmPOJVMeta?.redirect,
    component: () => import("/usr/src/app/pages/team/Team.vue").then(m => m.default || m)
  },
  {
    name: Team8jhszmPOJVMeta?.name ?? "team-Team___en",
    path: Team8jhszmPOJVMeta?.path ?? "/team/Team",
    meta: Team8jhszmPOJVMeta || {},
    alias: Team8jhszmPOJVMeta?.alias || [],
    redirect: Team8jhszmPOJVMeta?.redirect,
    component: () => import("/usr/src/app/pages/team/Team.vue").then(m => m.default || m)
  },
  {
    name: previewRGYKYr0i5AMeta?.name ?? "preview___de",
    path: previewRGYKYr0i5AMeta?.path ?? "/de/preview",
    meta: previewRGYKYr0i5AMeta || {},
    alias: previewRGYKYr0i5AMeta?.alias || [],
    redirect: previewRGYKYr0i5AMeta?.redirect,
    component: () => import("/usr/src/app/pages/preview.vue").then(m => m.default || m)
  },
  {
    name: previewRGYKYr0i5AMeta?.name ?? "preview___fr",
    path: previewRGYKYr0i5AMeta?.path ?? "/fr/preview",
    meta: previewRGYKYr0i5AMeta || {},
    alias: previewRGYKYr0i5AMeta?.alias || [],
    redirect: previewRGYKYr0i5AMeta?.redirect,
    component: () => import("/usr/src/app/pages/preview.vue").then(m => m.default || m)
  },
  {
    name: previewRGYKYr0i5AMeta?.name ?? "preview___en",
    path: previewRGYKYr0i5AMeta?.path ?? "/preview",
    meta: previewRGYKYr0i5AMeta || {},
    alias: previewRGYKYr0i5AMeta?.alias || [],
    redirect: previewRGYKYr0i5AMeta?.redirect,
    component: () => import("/usr/src/app/pages/preview.vue").then(m => m.default || m)
  },
  {
    name: SearchU77M4RgEh5Meta?.name ?? "search___de",
    path: SearchU77M4RgEh5Meta?.path ?? "/de/suche",
    meta: SearchU77M4RgEh5Meta || {},
    alias: SearchU77M4RgEh5Meta?.alias || [],
    redirect: SearchU77M4RgEh5Meta?.redirect,
    component: () => import("/usr/src/app/pages/search/Search.vue").then(m => m.default || m)
  },
  {
    name: SearchU77M4RgEh5Meta?.name ?? "search___fr",
    path: SearchU77M4RgEh5Meta?.path ?? "/fr/recherche",
    meta: SearchU77M4RgEh5Meta || {},
    alias: SearchU77M4RgEh5Meta?.alias || [],
    redirect: SearchU77M4RgEh5Meta?.redirect,
    component: () => import("/usr/src/app/pages/search/Search.vue").then(m => m.default || m)
  },
  {
    name: SearchU77M4RgEh5Meta?.name ?? "search___en",
    path: SearchU77M4RgEh5Meta?.path ?? "/search",
    meta: SearchU77M4RgEh5Meta || {},
    alias: SearchU77M4RgEh5Meta?.alias || [],
    redirect: SearchU77M4RgEh5Meta?.redirect,
    component: () => import("/usr/src/app/pages/search/Search.vue").then(m => m.default || m)
  },
  {
    name: OrderDetail9a7txqcZJxMeta?.name ?? "order-detail___de",
    path: OrderDetail9a7txqcZJxMeta?.path ?? "/de/konto/bestellung/:id()",
    meta: OrderDetail9a7txqcZJxMeta || {},
    alias: OrderDetail9a7txqcZJxMeta?.alias || [],
    redirect: OrderDetail9a7txqcZJxMeta?.redirect,
    component: () => import("/usr/src/app/pages/account/OrderDetail.vue").then(m => m.default || m)
  },
  {
    name: OrderDetail9a7txqcZJxMeta?.name ?? "order-detail___fr",
    path: OrderDetail9a7txqcZJxMeta?.path ?? "/fr/compte/commande/:id()",
    meta: OrderDetail9a7txqcZJxMeta || {},
    alias: OrderDetail9a7txqcZJxMeta?.alias || [],
    redirect: OrderDetail9a7txqcZJxMeta?.redirect,
    component: () => import("/usr/src/app/pages/account/OrderDetail.vue").then(m => m.default || m)
  },
  {
    name: OrderDetail9a7txqcZJxMeta?.name ?? "order-detail___en",
    path: OrderDetail9a7txqcZJxMeta?.path ?? "/account/order/:id",
    meta: OrderDetail9a7txqcZJxMeta || {},
    alias: OrderDetail9a7txqcZJxMeta?.alias || [],
    redirect: OrderDetail9a7txqcZJxMeta?.redirect,
    component: () => import("/usr/src/app/pages/account/OrderDetail.vue").then(m => m.default || m)
  },
  {
    name: Overview0AwmiEJ9ISMeta?.name ?? "account___de",
    path: Overview0AwmiEJ9ISMeta?.path ?? "/de/konto",
    meta: Overview0AwmiEJ9ISMeta || {},
    alias: Overview0AwmiEJ9ISMeta?.alias || [],
    redirect: Overview0AwmiEJ9ISMeta?.redirect,
    component: () => import("/usr/src/app/pages/account/Overview.vue").then(m => m.default || m)
  },
  {
    name: Overview0AwmiEJ9ISMeta?.name ?? "account___fr",
    path: Overview0AwmiEJ9ISMeta?.path ?? "/fr/compte",
    meta: Overview0AwmiEJ9ISMeta || {},
    alias: Overview0AwmiEJ9ISMeta?.alias || [],
    redirect: Overview0AwmiEJ9ISMeta?.redirect,
    component: () => import("/usr/src/app/pages/account/Overview.vue").then(m => m.default || m)
  },
  {
    name: Overview0AwmiEJ9ISMeta?.name ?? "account___en",
    path: Overview0AwmiEJ9ISMeta?.path ?? "/account",
    meta: Overview0AwmiEJ9ISMeta || {},
    alias: Overview0AwmiEJ9ISMeta?.alias || [],
    redirect: Overview0AwmiEJ9ISMeta?.redirect,
    component: () => import("/usr/src/app/pages/account/Overview.vue").then(m => m.default || m)
  },
  {
    name: Overview8TBUh1sCglMeta?.name ?? "account-address-overview___de",
    path: Overview8TBUh1sCglMeta?.path ?? "/de/konto/adressen",
    meta: Overview8TBUh1sCglMeta || {},
    alias: Overview8TBUh1sCglMeta?.alias || [],
    redirect: Overview8TBUh1sCglMeta?.redirect,
    component: () => import("/usr/src/app/pages/account/address/Overview.vue").then(m => m.default || m)
  },
  {
    name: Overview8TBUh1sCglMeta?.name ?? "account-address-overview___fr",
    path: Overview8TBUh1sCglMeta?.path ?? "/fr/compte/adresse",
    meta: Overview8TBUh1sCglMeta || {},
    alias: Overview8TBUh1sCglMeta?.alias || [],
    redirect: Overview8TBUh1sCglMeta?.redirect,
    component: () => import("/usr/src/app/pages/account/address/Overview.vue").then(m => m.default || m)
  },
  {
    name: Overview8TBUh1sCglMeta?.name ?? "account-address-overview___en",
    path: Overview8TBUh1sCglMeta?.path ?? "/account/address",
    meta: Overview8TBUh1sCglMeta || {},
    alias: Overview8TBUh1sCglMeta?.alias || [],
    redirect: Overview8TBUh1sCglMeta?.redirect,
    component: () => import("/usr/src/app/pages/account/address/Overview.vue").then(m => m.default || m)
  },
  {
    name: Addn3V91uhFr3Meta?.name ?? "account-address-new___de",
    path: Addn3V91uhFr3Meta?.path ?? "/de/konto/addressen/neu",
    meta: Addn3V91uhFr3Meta || {},
    alias: Addn3V91uhFr3Meta?.alias || [],
    redirect: Addn3V91uhFr3Meta?.redirect,
    component: () => import("/usr/src/app/pages/account/address/Add.vue").then(m => m.default || m)
  },
  {
    name: Addn3V91uhFr3Meta?.name ?? "account-address-new___fr",
    path: Addn3V91uhFr3Meta?.path ?? "/fr/compte/adresse/nouveau",
    meta: Addn3V91uhFr3Meta || {},
    alias: Addn3V91uhFr3Meta?.alias || [],
    redirect: Addn3V91uhFr3Meta?.redirect,
    component: () => import("/usr/src/app/pages/account/address/Add.vue").then(m => m.default || m)
  },
  {
    name: Addn3V91uhFr3Meta?.name ?? "account-address-new___en",
    path: Addn3V91uhFr3Meta?.path ?? "/account/address/new",
    meta: Addn3V91uhFr3Meta || {},
    alias: Addn3V91uhFr3Meta?.alias || [],
    redirect: Addn3V91uhFr3Meta?.redirect,
    component: () => import("/usr/src/app/pages/account/address/Add.vue").then(m => m.default || m)
  },
  {
    name: EditjwDUcx0xBgMeta?.name ?? "account-address-edit___de",
    path: EditjwDUcx0xBgMeta?.path ?? "/de/konto/addressen/:id()",
    meta: EditjwDUcx0xBgMeta || {},
    alias: EditjwDUcx0xBgMeta?.alias || [],
    redirect: EditjwDUcx0xBgMeta?.redirect,
    component: () => import("/usr/src/app/pages/account/address/Edit.vue").then(m => m.default || m)
  },
  {
    name: EditjwDUcx0xBgMeta?.name ?? "account-address-edit___fr",
    path: EditjwDUcx0xBgMeta?.path ?? "/fr/compte/adresse/editer/\\:id",
    meta: EditjwDUcx0xBgMeta || {},
    alias: EditjwDUcx0xBgMeta?.alias || [],
    redirect: EditjwDUcx0xBgMeta?.redirect,
    component: () => import("/usr/src/app/pages/account/address/Edit.vue").then(m => m.default || m)
  },
  {
    name: EditjwDUcx0xBgMeta?.name ?? "account-address-edit___en",
    path: EditjwDUcx0xBgMeta?.path ?? "/account/address/edit/:id",
    meta: EditjwDUcx0xBgMeta || {},
    alias: EditjwDUcx0xBgMeta?.alias || [],
    redirect: EditjwDUcx0xBgMeta?.redirect,
    component: () => import("/usr/src/app/pages/account/address/Edit.vue").then(m => m.default || m)
  },
  {
    name: AddressZ7kpmHC7V0Meta?.name ?? "checkout-guest-shipping___de",
    path: AddressZ7kpmHC7V0Meta?.path ?? "/de/checkout/gast",
    meta: AddressZ7kpmHC7V0Meta || {},
    alias: AddressZ7kpmHC7V0Meta?.alias || [],
    redirect: AddressZ7kpmHC7V0Meta?.redirect,
    component: () => import("/usr/src/app/pages/checkout/Address.vue").then(m => m.default || m)
  },
  {
    name: AddressZ7kpmHC7V0Meta?.name ?? "checkout-guest-shipping___fr",
    path: AddressZ7kpmHC7V0Meta?.path ?? "/fr/sortie-de-caisse/invite",
    meta: AddressZ7kpmHC7V0Meta || {},
    alias: AddressZ7kpmHC7V0Meta?.alias || [],
    redirect: AddressZ7kpmHC7V0Meta?.redirect,
    component: () => import("/usr/src/app/pages/checkout/Address.vue").then(m => m.default || m)
  },
  {
    name: AddressZ7kpmHC7V0Meta?.name ?? "checkout-guest-shipping___en",
    path: AddressZ7kpmHC7V0Meta?.path ?? "/checkout/guest",
    meta: AddressZ7kpmHC7V0Meta || {},
    alias: AddressZ7kpmHC7V0Meta?.alias || [],
    redirect: AddressZ7kpmHC7V0Meta?.redirect,
    component: () => import("/usr/src/app/pages/checkout/Address.vue").then(m => m.default || m)
  },
  {
    name: Shipping1oZZnAgsVaMeta?.name ?? "checkout-user-shipping___de",
    path: Shipping1oZZnAgsVaMeta?.path ?? "/de/checkout/versand",
    meta: Shipping1oZZnAgsVaMeta || {},
    alias: Shipping1oZZnAgsVaMeta?.alias || [],
    redirect: Shipping1oZZnAgsVaMeta?.redirect,
    component: () => import("/usr/src/app/pages/checkout/Shipping.vue").then(m => m.default || m)
  },
  {
    name: Shipping1oZZnAgsVaMeta?.name ?? "checkout-user-shipping___fr",
    path: Shipping1oZZnAgsVaMeta?.path ?? "/fr/sortie-de-caisse/expedition",
    meta: Shipping1oZZnAgsVaMeta || {},
    alias: Shipping1oZZnAgsVaMeta?.alias || [],
    redirect: Shipping1oZZnAgsVaMeta?.redirect,
    component: () => import("/usr/src/app/pages/checkout/Shipping.vue").then(m => m.default || m)
  },
  {
    name: Shipping1oZZnAgsVaMeta?.name ?? "checkout-user-shipping___en",
    path: Shipping1oZZnAgsVaMeta?.path ?? "/checkout/shipping",
    meta: Shipping1oZZnAgsVaMeta || {},
    alias: Shipping1oZZnAgsVaMeta?.alias || [],
    redirect: Shipping1oZZnAgsVaMeta?.redirect,
    component: () => import("/usr/src/app/pages/checkout/Shipping.vue").then(m => m.default || m)
  },
  {
    name: SummaryDoRytxW7t7Meta?.name ?? "checkout-summary___de",
    path: SummaryDoRytxW7t7Meta?.path ?? "/de/checkout/ubersicht",
    meta: SummaryDoRytxW7t7Meta || {},
    alias: SummaryDoRytxW7t7Meta?.alias || [],
    redirect: SummaryDoRytxW7t7Meta?.redirect,
    component: () => import("/usr/src/app/pages/checkout/Summary.vue").then(m => m.default || m)
  },
  {
    name: SummaryDoRytxW7t7Meta?.name ?? "checkout-summary___fr",
    path: SummaryDoRytxW7t7Meta?.path ?? "/fr/sortie-de-caisse/resume",
    meta: SummaryDoRytxW7t7Meta || {},
    alias: SummaryDoRytxW7t7Meta?.alias || [],
    redirect: SummaryDoRytxW7t7Meta?.redirect,
    component: () => import("/usr/src/app/pages/checkout/Summary.vue").then(m => m.default || m)
  },
  {
    name: SummaryDoRytxW7t7Meta?.name ?? "checkout-summary___en",
    path: SummaryDoRytxW7t7Meta?.path ?? "/checkout/summary",
    meta: SummaryDoRytxW7t7Meta || {},
    alias: SummaryDoRytxW7t7Meta?.alias || [],
    redirect: SummaryDoRytxW7t7Meta?.redirect,
    component: () => import("/usr/src/app/pages/checkout/Summary.vue").then(m => m.default || m)
  },
  {
    name: SaferpayMyPYuhOK5TMeta?.name ?? "checkout-payment___de",
    path: SaferpayMyPYuhOK5TMeta?.path ?? "/de/checkout/bezahlung",
    meta: SaferpayMyPYuhOK5TMeta || {},
    alias: SaferpayMyPYuhOK5TMeta?.alias || [],
    redirect: SaferpayMyPYuhOK5TMeta?.redirect,
    component: () => import("/usr/src/app/pages/checkout/Saferpay.vue").then(m => m.default || m)
  },
  {
    name: SaferpayMyPYuhOK5TMeta?.name ?? "checkout-payment___fr",
    path: SaferpayMyPYuhOK5TMeta?.path ?? "/fr/sortie-de-caisse/paiement",
    meta: SaferpayMyPYuhOK5TMeta || {},
    alias: SaferpayMyPYuhOK5TMeta?.alias || [],
    redirect: SaferpayMyPYuhOK5TMeta?.redirect,
    component: () => import("/usr/src/app/pages/checkout/Saferpay.vue").then(m => m.default || m)
  },
  {
    name: SaferpayMyPYuhOK5TMeta?.name ?? "checkout-payment___en",
    path: SaferpayMyPYuhOK5TMeta?.path ?? "/checkout/payment",
    meta: SaferpayMyPYuhOK5TMeta || {},
    alias: SaferpayMyPYuhOK5TMeta?.alias || [],
    redirect: SaferpayMyPYuhOK5TMeta?.redirect,
    component: () => import("/usr/src/app/pages/checkout/Saferpay.vue").then(m => m.default || m)
  },
  {
    name: PreLoginYBzpMz37jgMeta?.name ?? "checkout___de",
    path: PreLoginYBzpMz37jgMeta?.path ?? "/de/checkout",
    meta: PreLoginYBzpMz37jgMeta || {},
    alias: PreLoginYBzpMz37jgMeta?.alias || [],
    redirect: PreLoginYBzpMz37jgMeta?.redirect,
    component: () => import("/usr/src/app/pages/checkout/PreLogin.vue").then(m => m.default || m)
  },
  {
    name: PreLoginYBzpMz37jgMeta?.name ?? "checkout___fr",
    path: PreLoginYBzpMz37jgMeta?.path ?? "/fr/sortie-de-caisse",
    meta: PreLoginYBzpMz37jgMeta || {},
    alias: PreLoginYBzpMz37jgMeta?.alias || [],
    redirect: PreLoginYBzpMz37jgMeta?.redirect,
    component: () => import("/usr/src/app/pages/checkout/PreLogin.vue").then(m => m.default || m)
  },
  {
    name: PreLoginYBzpMz37jgMeta?.name ?? "checkout___en",
    path: PreLoginYBzpMz37jgMeta?.path ?? "/checkout",
    meta: PreLoginYBzpMz37jgMeta || {},
    alias: PreLoginYBzpMz37jgMeta?.alias || [],
    redirect: PreLoginYBzpMz37jgMeta?.redirect,
    component: () => import("/usr/src/app/pages/checkout/PreLogin.vue").then(m => m.default || m)
  },
  {
    name: ThankYouMO5fsgDbFTMeta?.name ?? "checkout-thankyou___de",
    path: ThankYouMO5fsgDbFTMeta?.path ?? "/de/checkout/danke",
    meta: ThankYouMO5fsgDbFTMeta || {},
    alias: ThankYouMO5fsgDbFTMeta?.alias || [],
    redirect: ThankYouMO5fsgDbFTMeta?.redirect,
    component: () => import("/usr/src/app/pages/checkout/ThankYou.vue").then(m => m.default || m)
  },
  {
    name: ThankYouMO5fsgDbFTMeta?.name ?? "checkout-thankyou___fr",
    path: ThankYouMO5fsgDbFTMeta?.path ?? "/fr/sortie-de-caisse/merci",
    meta: ThankYouMO5fsgDbFTMeta || {},
    alias: ThankYouMO5fsgDbFTMeta?.alias || [],
    redirect: ThankYouMO5fsgDbFTMeta?.redirect,
    component: () => import("/usr/src/app/pages/checkout/ThankYou.vue").then(m => m.default || m)
  },
  {
    name: ThankYouMO5fsgDbFTMeta?.name ?? "checkout-thankyou___en",
    path: ThankYouMO5fsgDbFTMeta?.path ?? "/checkout/thankyou",
    meta: ThankYouMO5fsgDbFTMeta || {},
    alias: ThankYouMO5fsgDbFTMeta?.alias || [],
    redirect: ThankYouMO5fsgDbFTMeta?.redirect,
    component: () => import("/usr/src/app/pages/checkout/ThankYou.vue").then(m => m.default || m)
  },
  {
    name: MemberXcd3K9Oac1Meta?.name ?? "account-member___de",
    path: MemberXcd3K9Oac1Meta?.path ?? "/de/konto/mitglied",
    meta: MemberXcd3K9Oac1Meta || {},
    alias: MemberXcd3K9Oac1Meta?.alias || [],
    redirect: MemberXcd3K9Oac1Meta?.redirect,
    component: () => import("/usr/src/app/pages/account/Member.vue").then(m => m.default || m)
  },
  {
    name: MemberXcd3K9Oac1Meta?.name ?? "account-member___fr",
    path: MemberXcd3K9Oac1Meta?.path ?? "/fr/compte/profil",
    meta: MemberXcd3K9Oac1Meta || {},
    alias: MemberXcd3K9Oac1Meta?.alias || [],
    redirect: MemberXcd3K9Oac1Meta?.redirect,
    component: () => import("/usr/src/app/pages/account/Member.vue").then(m => m.default || m)
  },
  {
    name: MemberXcd3K9Oac1Meta?.name ?? "account-member___en",
    path: MemberXcd3K9Oac1Meta?.path ?? "/account/member",
    meta: MemberXcd3K9Oac1Meta || {},
    alias: MemberXcd3K9Oac1Meta?.alias || [],
    redirect: MemberXcd3K9Oac1Meta?.redirect,
    component: () => import("/usr/src/app/pages/account/Member.vue").then(m => m.default || m)
  },
  {
    name: previewRGYKYr0i5AMeta?.name ?? "preview___de",
    path: previewRGYKYr0i5AMeta?.path ?? "/de/preview",
    meta: previewRGYKYr0i5AMeta || {},
    alias: previewRGYKYr0i5AMeta?.alias || [],
    redirect: previewRGYKYr0i5AMeta?.redirect,
    component: () => import("/usr/src/app/pages/preview.vue").then(m => m.default || m)
  },
  {
    name: previewRGYKYr0i5AMeta?.name ?? "preview___fr",
    path: previewRGYKYr0i5AMeta?.path ?? "/fr/preview",
    meta: previewRGYKYr0i5AMeta || {},
    alias: previewRGYKYr0i5AMeta?.alias || [],
    redirect: previewRGYKYr0i5AMeta?.redirect,
    component: () => import("/usr/src/app/pages/preview.vue").then(m => m.default || m)
  },
  {
    name: previewRGYKYr0i5AMeta?.name ?? "preview___en",
    path: previewRGYKYr0i5AMeta?.path ?? "/preview",
    meta: previewRGYKYr0i5AMeta || {},
    alias: previewRGYKYr0i5AMeta?.alias || [],
    redirect: previewRGYKYr0i5AMeta?.redirect,
    component: () => import("/usr/src/app/pages/preview.vue").then(m => m.default || m)
  },
  {
    name: SearchU77M4RgEh5Meta?.name ?? "search___de",
    path: SearchU77M4RgEh5Meta?.path ?? "/de/suche",
    meta: SearchU77M4RgEh5Meta || {},
    alias: SearchU77M4RgEh5Meta?.alias || [],
    redirect: SearchU77M4RgEh5Meta?.redirect,
    component: () => import("/usr/src/app/pages/search/Search.vue").then(m => m.default || m)
  },
  {
    name: SearchU77M4RgEh5Meta?.name ?? "search___fr",
    path: SearchU77M4RgEh5Meta?.path ?? "/fr/recherche",
    meta: SearchU77M4RgEh5Meta || {},
    alias: SearchU77M4RgEh5Meta?.alias || [],
    redirect: SearchU77M4RgEh5Meta?.redirect,
    component: () => import("/usr/src/app/pages/search/Search.vue").then(m => m.default || m)
  },
  {
    name: SearchU77M4RgEh5Meta?.name ?? "search___en",
    path: SearchU77M4RgEh5Meta?.path ?? "/search",
    meta: SearchU77M4RgEh5Meta || {},
    alias: SearchU77M4RgEh5Meta?.alias || [],
    redirect: SearchU77M4RgEh5Meta?.redirect,
    component: () => import("/usr/src/app/pages/search/Search.vue").then(m => m.default || m)
  },
  {
    name: OrderDetail9a7txqcZJxMeta?.name ?? "order-detail___de",
    path: OrderDetail9a7txqcZJxMeta?.path ?? "/de/konto/bestellung/:id()",
    meta: OrderDetail9a7txqcZJxMeta || {},
    alias: OrderDetail9a7txqcZJxMeta?.alias || [],
    redirect: OrderDetail9a7txqcZJxMeta?.redirect,
    component: () => import("/usr/src/app/pages/account/OrderDetail.vue").then(m => m.default || m)
  },
  {
    name: OrderDetail9a7txqcZJxMeta?.name ?? "order-detail___fr",
    path: OrderDetail9a7txqcZJxMeta?.path ?? "/fr/compte/commande/:id()",
    meta: OrderDetail9a7txqcZJxMeta || {},
    alias: OrderDetail9a7txqcZJxMeta?.alias || [],
    redirect: OrderDetail9a7txqcZJxMeta?.redirect,
    component: () => import("/usr/src/app/pages/account/OrderDetail.vue").then(m => m.default || m)
  },
  {
    name: OrderDetail9a7txqcZJxMeta?.name ?? "order-detail___en",
    path: OrderDetail9a7txqcZJxMeta?.path ?? "/account/order/:id",
    meta: OrderDetail9a7txqcZJxMeta || {},
    alias: OrderDetail9a7txqcZJxMeta?.alias || [],
    redirect: OrderDetail9a7txqcZJxMeta?.redirect,
    component: () => import("/usr/src/app/pages/account/OrderDetail.vue").then(m => m.default || m)
  },
  {
    name: Overview0AwmiEJ9ISMeta?.name ?? "account___de",
    path: Overview0AwmiEJ9ISMeta?.path ?? "/de/konto",
    meta: Overview0AwmiEJ9ISMeta || {},
    alias: Overview0AwmiEJ9ISMeta?.alias || [],
    redirect: Overview0AwmiEJ9ISMeta?.redirect,
    component: () => import("/usr/src/app/pages/account/Overview.vue").then(m => m.default || m)
  },
  {
    name: Overview0AwmiEJ9ISMeta?.name ?? "account___fr",
    path: Overview0AwmiEJ9ISMeta?.path ?? "/fr/compte",
    meta: Overview0AwmiEJ9ISMeta || {},
    alias: Overview0AwmiEJ9ISMeta?.alias || [],
    redirect: Overview0AwmiEJ9ISMeta?.redirect,
    component: () => import("/usr/src/app/pages/account/Overview.vue").then(m => m.default || m)
  },
  {
    name: Overview0AwmiEJ9ISMeta?.name ?? "account___en",
    path: Overview0AwmiEJ9ISMeta?.path ?? "/account",
    meta: Overview0AwmiEJ9ISMeta || {},
    alias: Overview0AwmiEJ9ISMeta?.alias || [],
    redirect: Overview0AwmiEJ9ISMeta?.redirect,
    component: () => import("/usr/src/app/pages/account/Overview.vue").then(m => m.default || m)
  },
  {
    name: Overview8TBUh1sCglMeta?.name ?? "account-address-overview___de",
    path: Overview8TBUh1sCglMeta?.path ?? "/de/konto/adressen",
    meta: Overview8TBUh1sCglMeta || {},
    alias: Overview8TBUh1sCglMeta?.alias || [],
    redirect: Overview8TBUh1sCglMeta?.redirect,
    component: () => import("/usr/src/app/pages/account/address/Overview.vue").then(m => m.default || m)
  },
  {
    name: Overview8TBUh1sCglMeta?.name ?? "account-address-overview___fr",
    path: Overview8TBUh1sCglMeta?.path ?? "/fr/compte/adresse",
    meta: Overview8TBUh1sCglMeta || {},
    alias: Overview8TBUh1sCglMeta?.alias || [],
    redirect: Overview8TBUh1sCglMeta?.redirect,
    component: () => import("/usr/src/app/pages/account/address/Overview.vue").then(m => m.default || m)
  },
  {
    name: Overview8TBUh1sCglMeta?.name ?? "account-address-overview___en",
    path: Overview8TBUh1sCglMeta?.path ?? "/account/address",
    meta: Overview8TBUh1sCglMeta || {},
    alias: Overview8TBUh1sCglMeta?.alias || [],
    redirect: Overview8TBUh1sCglMeta?.redirect,
    component: () => import("/usr/src/app/pages/account/address/Overview.vue").then(m => m.default || m)
  },
  {
    name: Addn3V91uhFr3Meta?.name ?? "account-address-new___de",
    path: Addn3V91uhFr3Meta?.path ?? "/de/konto/addressen/neu",
    meta: Addn3V91uhFr3Meta || {},
    alias: Addn3V91uhFr3Meta?.alias || [],
    redirect: Addn3V91uhFr3Meta?.redirect,
    component: () => import("/usr/src/app/pages/account/address/Add.vue").then(m => m.default || m)
  },
  {
    name: Addn3V91uhFr3Meta?.name ?? "account-address-new___fr",
    path: Addn3V91uhFr3Meta?.path ?? "/fr/compte/adresse/nouveau",
    meta: Addn3V91uhFr3Meta || {},
    alias: Addn3V91uhFr3Meta?.alias || [],
    redirect: Addn3V91uhFr3Meta?.redirect,
    component: () => import("/usr/src/app/pages/account/address/Add.vue").then(m => m.default || m)
  },
  {
    name: Addn3V91uhFr3Meta?.name ?? "account-address-new___en",
    path: Addn3V91uhFr3Meta?.path ?? "/account/address/new",
    meta: Addn3V91uhFr3Meta || {},
    alias: Addn3V91uhFr3Meta?.alias || [],
    redirect: Addn3V91uhFr3Meta?.redirect,
    component: () => import("/usr/src/app/pages/account/address/Add.vue").then(m => m.default || m)
  },
  {
    name: EditjwDUcx0xBgMeta?.name ?? "account-address-edit___de",
    path: EditjwDUcx0xBgMeta?.path ?? "/de/konto/addressen/:id()",
    meta: EditjwDUcx0xBgMeta || {},
    alias: EditjwDUcx0xBgMeta?.alias || [],
    redirect: EditjwDUcx0xBgMeta?.redirect,
    component: () => import("/usr/src/app/pages/account/address/Edit.vue").then(m => m.default || m)
  },
  {
    name: EditjwDUcx0xBgMeta?.name ?? "account-address-edit___fr",
    path: EditjwDUcx0xBgMeta?.path ?? "/fr/compte/adresse/editer/\\:id",
    meta: EditjwDUcx0xBgMeta || {},
    alias: EditjwDUcx0xBgMeta?.alias || [],
    redirect: EditjwDUcx0xBgMeta?.redirect,
    component: () => import("/usr/src/app/pages/account/address/Edit.vue").then(m => m.default || m)
  },
  {
    name: EditjwDUcx0xBgMeta?.name ?? "account-address-edit___en",
    path: EditjwDUcx0xBgMeta?.path ?? "/account/address/edit/:id",
    meta: EditjwDUcx0xBgMeta || {},
    alias: EditjwDUcx0xBgMeta?.alias || [],
    redirect: EditjwDUcx0xBgMeta?.redirect,
    component: () => import("/usr/src/app/pages/account/address/Edit.vue").then(m => m.default || m)
  },
  {
    name: AddressZ7kpmHC7V0Meta?.name ?? "checkout-guest-shipping___de",
    path: AddressZ7kpmHC7V0Meta?.path ?? "/de/checkout/gast",
    meta: AddressZ7kpmHC7V0Meta || {},
    alias: AddressZ7kpmHC7V0Meta?.alias || [],
    redirect: AddressZ7kpmHC7V0Meta?.redirect,
    component: () => import("/usr/src/app/pages/checkout/Address.vue").then(m => m.default || m)
  },
  {
    name: AddressZ7kpmHC7V0Meta?.name ?? "checkout-guest-shipping___fr",
    path: AddressZ7kpmHC7V0Meta?.path ?? "/fr/sortie-de-caisse/invite",
    meta: AddressZ7kpmHC7V0Meta || {},
    alias: AddressZ7kpmHC7V0Meta?.alias || [],
    redirect: AddressZ7kpmHC7V0Meta?.redirect,
    component: () => import("/usr/src/app/pages/checkout/Address.vue").then(m => m.default || m)
  },
  {
    name: AddressZ7kpmHC7V0Meta?.name ?? "checkout-guest-shipping___en",
    path: AddressZ7kpmHC7V0Meta?.path ?? "/checkout/guest",
    meta: AddressZ7kpmHC7V0Meta || {},
    alias: AddressZ7kpmHC7V0Meta?.alias || [],
    redirect: AddressZ7kpmHC7V0Meta?.redirect,
    component: () => import("/usr/src/app/pages/checkout/Address.vue").then(m => m.default || m)
  },
  {
    name: Shipping1oZZnAgsVaMeta?.name ?? "checkout-user-shipping___de",
    path: Shipping1oZZnAgsVaMeta?.path ?? "/de/checkout/versand",
    meta: Shipping1oZZnAgsVaMeta || {},
    alias: Shipping1oZZnAgsVaMeta?.alias || [],
    redirect: Shipping1oZZnAgsVaMeta?.redirect,
    component: () => import("/usr/src/app/pages/checkout/Shipping.vue").then(m => m.default || m)
  },
  {
    name: Shipping1oZZnAgsVaMeta?.name ?? "checkout-user-shipping___fr",
    path: Shipping1oZZnAgsVaMeta?.path ?? "/fr/sortie-de-caisse/expedition",
    meta: Shipping1oZZnAgsVaMeta || {},
    alias: Shipping1oZZnAgsVaMeta?.alias || [],
    redirect: Shipping1oZZnAgsVaMeta?.redirect,
    component: () => import("/usr/src/app/pages/checkout/Shipping.vue").then(m => m.default || m)
  },
  {
    name: Shipping1oZZnAgsVaMeta?.name ?? "checkout-user-shipping___en",
    path: Shipping1oZZnAgsVaMeta?.path ?? "/checkout/shipping",
    meta: Shipping1oZZnAgsVaMeta || {},
    alias: Shipping1oZZnAgsVaMeta?.alias || [],
    redirect: Shipping1oZZnAgsVaMeta?.redirect,
    component: () => import("/usr/src/app/pages/checkout/Shipping.vue").then(m => m.default || m)
  },
  {
    name: SummaryDoRytxW7t7Meta?.name ?? "checkout-summary___de",
    path: SummaryDoRytxW7t7Meta?.path ?? "/de/checkout/ubersicht",
    meta: SummaryDoRytxW7t7Meta || {},
    alias: SummaryDoRytxW7t7Meta?.alias || [],
    redirect: SummaryDoRytxW7t7Meta?.redirect,
    component: () => import("/usr/src/app/pages/checkout/Summary.vue").then(m => m.default || m)
  },
  {
    name: SummaryDoRytxW7t7Meta?.name ?? "checkout-summary___fr",
    path: SummaryDoRytxW7t7Meta?.path ?? "/fr/sortie-de-caisse/resume",
    meta: SummaryDoRytxW7t7Meta || {},
    alias: SummaryDoRytxW7t7Meta?.alias || [],
    redirect: SummaryDoRytxW7t7Meta?.redirect,
    component: () => import("/usr/src/app/pages/checkout/Summary.vue").then(m => m.default || m)
  },
  {
    name: SummaryDoRytxW7t7Meta?.name ?? "checkout-summary___en",
    path: SummaryDoRytxW7t7Meta?.path ?? "/checkout/summary",
    meta: SummaryDoRytxW7t7Meta || {},
    alias: SummaryDoRytxW7t7Meta?.alias || [],
    redirect: SummaryDoRytxW7t7Meta?.redirect,
    component: () => import("/usr/src/app/pages/checkout/Summary.vue").then(m => m.default || m)
  },
  {
    name: SaferpayMyPYuhOK5TMeta?.name ?? "checkout-payment___de",
    path: SaferpayMyPYuhOK5TMeta?.path ?? "/de/checkout/bezahlung",
    meta: SaferpayMyPYuhOK5TMeta || {},
    alias: SaferpayMyPYuhOK5TMeta?.alias || [],
    redirect: SaferpayMyPYuhOK5TMeta?.redirect,
    component: () => import("/usr/src/app/pages/checkout/Saferpay.vue").then(m => m.default || m)
  },
  {
    name: SaferpayMyPYuhOK5TMeta?.name ?? "checkout-payment___fr",
    path: SaferpayMyPYuhOK5TMeta?.path ?? "/fr/sortie-de-caisse/paiement",
    meta: SaferpayMyPYuhOK5TMeta || {},
    alias: SaferpayMyPYuhOK5TMeta?.alias || [],
    redirect: SaferpayMyPYuhOK5TMeta?.redirect,
    component: () => import("/usr/src/app/pages/checkout/Saferpay.vue").then(m => m.default || m)
  },
  {
    name: SaferpayMyPYuhOK5TMeta?.name ?? "checkout-payment___en",
    path: SaferpayMyPYuhOK5TMeta?.path ?? "/checkout/payment",
    meta: SaferpayMyPYuhOK5TMeta || {},
    alias: SaferpayMyPYuhOK5TMeta?.alias || [],
    redirect: SaferpayMyPYuhOK5TMeta?.redirect,
    component: () => import("/usr/src/app/pages/checkout/Saferpay.vue").then(m => m.default || m)
  },
  {
    name: PreLoginYBzpMz37jgMeta?.name ?? "checkout___de",
    path: PreLoginYBzpMz37jgMeta?.path ?? "/de/checkout",
    meta: PreLoginYBzpMz37jgMeta || {},
    alias: PreLoginYBzpMz37jgMeta?.alias || [],
    redirect: PreLoginYBzpMz37jgMeta?.redirect,
    component: () => import("/usr/src/app/pages/checkout/PreLogin.vue").then(m => m.default || m)
  },
  {
    name: PreLoginYBzpMz37jgMeta?.name ?? "checkout___fr",
    path: PreLoginYBzpMz37jgMeta?.path ?? "/fr/sortie-de-caisse",
    meta: PreLoginYBzpMz37jgMeta || {},
    alias: PreLoginYBzpMz37jgMeta?.alias || [],
    redirect: PreLoginYBzpMz37jgMeta?.redirect,
    component: () => import("/usr/src/app/pages/checkout/PreLogin.vue").then(m => m.default || m)
  },
  {
    name: PreLoginYBzpMz37jgMeta?.name ?? "checkout___en",
    path: PreLoginYBzpMz37jgMeta?.path ?? "/checkout",
    meta: PreLoginYBzpMz37jgMeta || {},
    alias: PreLoginYBzpMz37jgMeta?.alias || [],
    redirect: PreLoginYBzpMz37jgMeta?.redirect,
    component: () => import("/usr/src/app/pages/checkout/PreLogin.vue").then(m => m.default || m)
  },
  {
    name: ThankYouMO5fsgDbFTMeta?.name ?? "checkout-thankyou___de",
    path: ThankYouMO5fsgDbFTMeta?.path ?? "/de/checkout/danke",
    meta: ThankYouMO5fsgDbFTMeta || {},
    alias: ThankYouMO5fsgDbFTMeta?.alias || [],
    redirect: ThankYouMO5fsgDbFTMeta?.redirect,
    component: () => import("/usr/src/app/pages/checkout/ThankYou.vue").then(m => m.default || m)
  },
  {
    name: ThankYouMO5fsgDbFTMeta?.name ?? "checkout-thankyou___fr",
    path: ThankYouMO5fsgDbFTMeta?.path ?? "/fr/sortie-de-caisse/merci",
    meta: ThankYouMO5fsgDbFTMeta || {},
    alias: ThankYouMO5fsgDbFTMeta?.alias || [],
    redirect: ThankYouMO5fsgDbFTMeta?.redirect,
    component: () => import("/usr/src/app/pages/checkout/ThankYou.vue").then(m => m.default || m)
  },
  {
    name: ThankYouMO5fsgDbFTMeta?.name ?? "checkout-thankyou___en",
    path: ThankYouMO5fsgDbFTMeta?.path ?? "/checkout/thankyou",
    meta: ThankYouMO5fsgDbFTMeta || {},
    alias: ThankYouMO5fsgDbFTMeta?.alias || [],
    redirect: ThankYouMO5fsgDbFTMeta?.redirect,
    component: () => import("/usr/src/app/pages/checkout/ThankYou.vue").then(m => m.default || m)
  },
  {
    name: MemberXcd3K9Oac1Meta?.name ?? "account-member___de",
    path: MemberXcd3K9Oac1Meta?.path ?? "/de/konto/mitglied",
    meta: MemberXcd3K9Oac1Meta || {},
    alias: MemberXcd3K9Oac1Meta?.alias || [],
    redirect: MemberXcd3K9Oac1Meta?.redirect,
    component: () => import("/usr/src/app/pages/account/Member.vue").then(m => m.default || m)
  },
  {
    name: MemberXcd3K9Oac1Meta?.name ?? "account-member___fr",
    path: MemberXcd3K9Oac1Meta?.path ?? "/fr/compte/profil",
    meta: MemberXcd3K9Oac1Meta || {},
    alias: MemberXcd3K9Oac1Meta?.alias || [],
    redirect: MemberXcd3K9Oac1Meta?.redirect,
    component: () => import("/usr/src/app/pages/account/Member.vue").then(m => m.default || m)
  },
  {
    name: MemberXcd3K9Oac1Meta?.name ?? "account-member___en",
    path: MemberXcd3K9Oac1Meta?.path ?? "/account/member",
    meta: MemberXcd3K9Oac1Meta || {},
    alias: MemberXcd3K9Oac1Meta?.alias || [],
    redirect: MemberXcd3K9Oac1Meta?.redirect,
    component: () => import("/usr/src/app/pages/account/Member.vue").then(m => m.default || m)
  }
]