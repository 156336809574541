<template>
    <component
        :is="component"
        class="l-button"
        :class="{
            'l-button--size-40': size40,
        }"
        :type="component == 'button' ? 'button' : null"
        :disabled="disabled"
        v-bind="linkProps"
    >
        <div class="l-button__label justify-content-center">
            <IconsWrapper
                size="22px"
                class="l-button__icon"
                color="black"
                v-if="$slots.default"
            >
                <slot></slot>
            </IconsWrapper>
            <div
                class="l-button__label-text"
                :class="{
                    'equal-base equal-base--tighter': !size40,
                    'equal-small-2': size40,
                }"
            >
                {{ label }}
            </div>
            <IconsWrapper
                size="16px"
                class="l-button__icon l-button__icon-after"
                v-if="$slots.iconAfter"
            >
                <slot name="iconAfter"></slot>
            </IconsWrapper>
        </div>
        <div class="equal-small-4 l-button__info" v-if="info">{{ info }}</div>
    </component>
</template>

<script setup>
import { getTarget } from '~/utils/externalLink';
const props = defineProps({
    component: {
        type: String,
        default: 'button', // "button" or "NuxtLink"
    },
    label: {
        type: String,
        default: '<LABEL>',
    },
    info: {
        type: String,
        default: '',
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    to: {
        type: String,
        default: '#',
    },
    rel: {
        type: String,
        default: '',
    },
    size40: {
        type: Boolean,
        default: false,
    },
    href: {
        type: String,
        default: '',
        required: false,
    },
});

const linkProps = computed(() => {
    const res = {};
    if (props.to) res.to = props.to;
    if (props.rel) res.rel = props.rel;
    if (props.target) res.target = props.target;
    if (props.href) res.href = props.href;
    return res;
});

const component = computed(() => {
    if (props.component === 'NuxtLink') return resolveComponent('NuxtLink');
    return props.component;
});

const target = computed(() => {
    try {
        return getTarget(props.to);
    } catch {
        return null;
    }
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
@import '@/node_modules/bootstrap/scss/mixins';

.l-button {
    display: inline-block;
    height: 50px;
    width: fit-content;
    max-width: 384px;
    min-width: min(100%, 240px);
    cursor: pointer;
    padding: 0;
    &:hover,
    &:focus,
    &:active {
        background-color: $color-plain-grey-80;
        transition: 0.5s ease;
    }
    @include media-breakpoint-down(400) {
        min-width: 0;
        width: 100%;
    }

    background-color: $color-light;
    border: 1px solid $color-light;
    border-radius: 50vh;
    text-decoration: none;
    transition: 0.5s ease;

    &__label {
        height: 100%;
        display: flex;
        align-items: center;

        margin: 0 30px;
        white-space: nowrap;
    }

    &__label-text {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: black;
    }

    &__icon {
        flex-shrink: 0;
        margin: auto 9px auto 0;

        &-after {
            margin: auto 0 auto 9px;
        }

        :deep(svg) {
            fill: black;
        }
    }

    &__info {
        display: block;
        position: relative;
        bottom: 50px;
        left: calc(100% - 14px);

        padding: 0 8px 0 8px;
        min-width: 22px;
        width: min-content;
        height: 17px;
        border-radius: 50vh;

        background-color: #31441e;
        text-align: center;
        line-height: 19px;
    }

    &:disabled,
    &--disabled {
        cursor: not-allowed;

        background-color: $color-dark-02;
        border: 1px solid $color-dark-01;

        .l-button {
            &__label-text {
                color: $color-disabled;
            }

            &__icon {
                :deep(svg) {
                    fill: $color-disabled;
                }
            }
        }
    }

    &--secondary {
        background-color: $color-dark-01;
        border: 1px solid $color-dark-01;
        &[disabled='true'],
        &[disabled='true']:hover {
            background-color: #000 !important;
            .l-button {
                &__label-text {
                    color: $color-dark-disabled !important;
                    border-color: $color-dark-disabled !important;
                }
            }
        }

        .l-button {
            &__label-text {
                color: $color-light;
            }

            &__icon {
                :deep(svg) {
                    fill: $color-light;
                }
            }
        }
        &:hover,
        &:focus,
        &:active {
            background-color: $color-dark-disabled;
            transition: 0.5s ease;
        }
    }

    &--outlined {
        background-color: unset;
        border: 1px solid $color-light;

        .l-button {
            &__label-text {
                color: $color-light;
            }

            &__icon {
                :deep(svg) {
                    fill: $color-light;
                }
            }
        }
        &:hover,
        &:focus,
        &:active {
            background-color: color-mix(
                in srgb,
                $color-light,
                transparent 80%
            ) !important;
            transition: 0.5s ease;
        }
    }

    &--icon-only {
        width: 52px;
        max-width: unset;
        min-width: unset;

        .l-button__label {
            height: 100%;
            margin: unset;
        }

        .l-button__label-text {
            display: none;
        }

        .l-button__icon {
            margin: auto;
        }
    }

    &--size-40 {
        height: 40px;

        .l-button__icon {
            :deep(svg) {
                height: 18px;
                width: 18px;
            }
        }

        .l-button__info {
            bottom: 40px;
            left: calc(100% - 8px);
        }

        .l-button__label-text {
            line-height: 38px;
        }
    }

    &--size-30 {
        height: 30px;

        .l-button__icon {
            :deep(svg) {
                height: 12px;
                width: 12px;
            }
        }

        .l-button__info {
            bottom: 30px;
            left: calc(100% - 8px);
        }

        .l-button__label-text {
            line-height: 28px;
        }
    }

    &--auto-width {
        width: auto;
        min-width: unset;
    }

    &--plain-grey-60 {
        border-color: #999999;
        background-color: #999999;

        &:active,
        &:focus,
        &:hover {
            border-color: #b3b3b3;
            background-color: #b3b3b3;
            transition: 0.5s ease;
        }
    }
}

.l-button--icon-only.l-button--outlined {
    border: 1px solid $color-disabled;
    background-color: $color-dark-02;
}

.l-button--icon-only.l-button--size-40 {
    width: 40px;
}

.l-button--icon-only.l-button--size-30 {
    width: 30px;
}
</style>
