import { navigateTo } from 'nuxt/app';

export default defineNuxtRouteMiddleware((to) => {
    if (to.path.includes('preview')) {
        return;
    }
    if (to.path[to.path.length - 1] !== '/') {
        to.path += '/';
        let redirectCode = 301;
        if (to.path === '/de/' || to.path === '/fr/') redirectCode = 302;
        return navigateTo({ path: to.path, query: to.query }, { redirectCode });
    }
});
