import axios from 'axios';
import { getCookie, cleanUrl } from './utils';
import { useAuthStore } from '~/stores/auth';

export class OscarClient {
    constructor(url, locale) {
        const authStore = useAuthStore();
        this.locale = locale;
        let headers = {};
        if (process.client && window && !window.productCache) {
            window.productCache = {};
        }
        if (process.client && window && !window.priceCache) {
            window.priceCache = {};
        }
        if (process.client && window && !window.availableCache) {
            window.availableCache = {};
        }
        if (!process.client) {
            headers['Host'] = new URL(url).host;
        }

        this.client = axios.create({
            baseURL: url,
            withCredentials: true,
            headers,
        });

        this.client.interceptors.request.use((config) => {
            config.headers['X-CSRFToken'] = process.client
                ? getCookie('csrftoken')
                : '';
            config.headers['Accept-Language'] = this.locale;
            if (authStore.authenticated && authStore?.token?.access) {
                config.headers['X-FORWARDED-ACCESS-TOKEN'] =
                    `${authStore?.token?.access}`;
            }
            return config;
        });

        this.client.interceptors.response.use(
            (res) => {
                return res;
            },
            (err) => {
                const originalRequest = err.config;
                if (
                    err?.response?.status === 403 &&
                    err?.response?.data?.detail?.startsWith('CSRF') &&
                    !originalRequest._retry
                ) {
                    originalRequest._retry = true;
                    return this.getUser().then((r) => {
                        originalRequest.headers['X-CSRFToken'] =
                            getCookie('csrftoken');
                        return this.client(originalRequest);
                    });
                }
                console.error(err);
                return Promise.reject(err);
            },
        );
    }

    async getUser() {
        return await this.client.get('/user/');
    }

    async logout() {
        return await this.client.get('/logout/');
    }

    async getBasket() {
        return await this.client.get('/basket/');
    }

    async search(q, filter, page) {
        return await this.client.get(`/search/full/`, {
            method: 'GET',
            params: { q, filter, page },
        });
    }

    async getLines(linesUrl) {
        return await this.client.get(cleanUrl(linesUrl));
    }

    async getProduct(productUrl) {
        const cacheString = `${productUrl}-${this.locale}`;
        if (Object.keys(window.productCache).includes(cacheString))
            return window.productCache[cacheString];
        return await this.client.get(cleanUrl(productUrl)).then((res) => {
            window.productCache[cacheString] = res;
            return res;
        });
    }

    async getMostPurchased() {
        if (window.mostOrdered) {
            return window.mostOrdered;
        }
        return await this.client.get('/orders/most-purchased/').then((res) => {
            window.mostOrdered = res;
            return res;
        });
    }

    async addProductToBasket(productUrl, quantity, options) {
        const data = {
            url: productUrl,
            quantity,
        };
        if (options) {
            data['options'] = options;
        }
        return await this.client.post('/basket/add-product/', data);
    }

    async getAvailability(productId, specialPrice) {
        let url = `products/${productId}`;
        if (specialPrice) {
            url += '/lightcustupdate/';
        } else {
            url += '/lightupdate/';
        }
        if (process.client && Object.keys(window.availableCache).includes(url))
            return window.availableCache[url];
        return await this.client.get(cleanUrl(url)).then((res) => {
            if (process.client) window.availableCache[url] = res;
            return res;
        });
    }

    async getPrice(productId) {
        const url = `products/${productId}/price/`;
        if (Object.keys(window.priceCache).includes(url))
            return window.priceCache[url];
        return await this.client.get(cleanUrl(url)).then((res) => {
            window.priceCache[url] = res;
            return res;
        });
    }

    async deleteLine(lineUrl) {
        return await this.client.delete(cleanUrl(lineUrl));
    }

    async updateLineQuantity(lineUrl, quantity) {
        return await this.client.patch(cleanUrl(lineUrl), {
            quantity,
        });
    }

    async getUserAddresses() {
        return await this.client.get('/addresses/');
    }

    async createUpdateAddress(address) {
        const method = address.id ? 'PUT' : 'POST';
        const url = address.id ? `/addresses/${address.id}/` : '/addresses/';
        return this.client.request({
            url,
            method,
            data: address,
        });
    }

    async deleteAddress(id) {
        return this.client.delete(`/addresses/${id}/`);
    }

    async getShippingSlots(postcode) {
        return this.client.get(`/shipping-slots/${postcode}/`);
    }

    async applyVoucher(code) {
        return this.client.post('/basket/voucher/', {
            vouchercode: code,
        });
    }
    async removeVoucher(code) {
        return this.client.delete(`/basket/voucher/${code}/`);
    }

    async transmitSaferpayInformation(kind, orderNumber, hash) {
        return this.client.get(`/payment/${kind}/${orderNumber}/${hash}/`);
    }

    async getSummary(slot) {
        return this.client.get(`/checkout/summary/${slot}/`);
    }

    async checkout(checkoutInfo) {
        return this.client.post('/checkout/', checkoutInfo);
    }

    async getOrders(limit, offset = 0) {
        return this.client.get(`/orders/?limit=${limit}&offset=${offset}`);
    }

    async getOrder(number) {
        return this.client.get(`/orders/${number}/`);
    }

    async getSettings() {
        return this.client.get(`/settings/`);
    }
}
