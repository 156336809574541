import { useAuthStore } from '@/stores/auth';

function getLocale(to) {
    const locale = to.path.split('/')[1];
    if (['de', 'fr'].includes(locale)) {
        return locale;
    }
    return 'de';
}

export default defineNuxtRouteMiddleware(async (to) => {
    if (process.env.HISTOIRE) return;
    const authStore = useAuthStore();
    const localeRoute = useLocalePath();
    const locale = getLocale(to);
    await authStore.init(locale);

    const authenticated = useState('authenticated');
    if (
        to?.name?.startsWith('checkout-Address') &&
        authenticated.value === true
    ) {
        const route = localeRoute({ name: 'checkout-user-shipping' });
        return navigateTo(route);
    }
    if (to?.name?.startsWith('account')) {
        if (authenticated.value === false) {
            return navigateTo(`/${locale}/`);
        }
    }
});
