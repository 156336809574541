import * as Sentry from '@sentry/vue';

async function lazyLoadSentryIntegrations() {
    // don't load on server
    if (window.sentIntegrationInit) return;
    window.sentIntegrationInit = true;
    const { Replay } = await import('@sentry/vue');
    Sentry.addIntegration(
        new Replay({
            maskAllText: false,
            blockAllMedia: false,
        }),
    );
}

function getSentryIntegrations() {
    // don't load on server
    if (window.sIntegrationLoaded) return [];

    const browserTracing = Sentry.browserTracingIntegration();
    window.sIntegrationLoaded = true;
    return [browserTracing];
}

export default defineNuxtPlugin({
    name: 'sentry',
    parallel: true,
    async setup(nuxtApp) {
        const vueApp = nuxtApp.vueApp;

        const config = useRuntimeConfig();

        Sentry.init({
            app: vueApp,
            dsn: config.public?.sentry?.dsn ?? null,
            release: config.public?.sentry?.release ?? null,
            integrations: getSentryIntegrations(),

            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: config.public.sentry?.tracesSampleRate,
            environment: config.public.sentry?.environment,

            // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
            // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

            // This sets the sample rate. You may want this to be 100% while
            // in development and sample at a lower rate in production
            replaysSessionSampleRate: config.public.sentry?.replaySampleRate,

            // If the entire session is not sampled, use the below sample rate to sample
            // sessions when an error occurs.
            replaysOnErrorSampleRate:
                config.public.sentry?.errorReplaySampleRate,
        });

        // Lazy-load the replay integration to reduce bundle size
        lazyLoadSentryIntegrations();
    },
});
