import { defineStore } from 'pinia';
import { reactive } from 'vue';
import axios from 'axios';
import { useOscarClient } from '~/composables/useOscarClient';
import { jwtDecode } from 'jwt-decode';
import { getCookie } from '~/api/utils';

export const useAuthStore = defineStore('auth', () => {
    var keycloak = null;

    const authenticated = ref(false);
    const registered = ref(false);
    const token = reactive({
        access: null,
        refresh: null,
    });
    const router = useRouter();

    const user = reactive({
        email: null,
        firstName: null,
        lastName: null,
        phone: null,
        birthday: null,
        id: null,
        invoicePermitted: null,
        specialPrice: null,
        customerDiscount: null,
    });

    const specialPrice = computed(() => {
        return user.specialPrice;
    });

    const hash = function (s) {
        for (var i = 0, h = 0xdeadbeef; i < s.length; i++)
            h = Math.imul(h ^ s.charCodeAt(i), 2654435761);
        return (h ^ (h >>> 16)) >>> 0;
    };

    const settings = ref(null);

    function updateSettings(locale) {
        if (!locale) {
            locale = 'de';
        }

        const oscar = useOscarClient(locale);

        oscar.getSettings().then((res) => {
            settings.value = res.data;
        });
    }

    async function updateUser(locale) {
        const config = useRuntimeConfig();
        const client = axios.create({
            baseURL: config.public.apiBase,
        });
        client.defaults.headers.common['x-forwarded-access-token'] =
            token.access;
        const response = await client.get('/user/', {
            withCredentials: true,
            headers: {
                'Accept-Language': `${locale}-${locale.toUpperCase()}`,
            },
        });
        if ('email' in response.data) {
            user.email = response.data.email;
            user.firstName = response.data.first_name;
            user.lastName = response.data.last_name;
            user.phone = response.data.phone;
            user.birthday = response.data.birth_date;
            user.invoicePermitted = response.data.invoice_permitted;
            user.specialPrice = response.data.has_special_prices || false;
            user.customerDiscount = response.data.customer_discount;
        }
        if (user.firstName && user.lastName) {
            registered.value = true;
        }
        return user;
    }
    function getCSRFToken() {
        const config = useRuntimeConfig();
        let url = config.public.apiBase;
        if (config.serverApiBase) {
            url = config.serverApiBase;
        }
        // Get CSRF Token from Userendpoint
        return axios.get(url + '/user/', { withCredentials: true });
    }
    async function init(locale) {
        const Keycloak = (await import('keycloak-js')).default;
        if (keycloak) {
            return;
        }
        if (!locale) {
            locale = 'de';
        }

        // client side only
        try {
            let client = null;
            client = process.client;
            if (!client) {
                return;
            }
        } catch (e) {
            return;
        }

        const config = useRuntimeConfig();
        getCSRFToken();
        // init keycloak
        if (keycloak === null) {
            keycloak = new Keycloak({
                url: config.public.keycloakBase,
                realm: 'LUMA',
                clientId: 'luma-onlineshop',
            });
        }
        refreshTokenHook(keycloak);
        token.access = localStorage.getItem('access');
        token.refresh = localStorage.getItem('refresh');
        keycloak.onAuthSuccess = () => {
            if (localStorage.getItem('loginTry') === '1') {
                localStorage.removeItem('loginTry');
                router.replace({ query: { alert: 'loggedIn' } });
            }
        };

        let data = {
            checkLoginIframe: false,
        };
        if (token.access && token.refresh) {
            Object.assign(data, {
                token: token.access,
                refreshToken: token.refresh,
            });
        }
        if (!accessTokenValid()) {
            if (!refreshTokenValid()) {
                if (token.access && token.refresh) {
                    const oscar = useOscarClient();
                    await oscar.logout();
                }
                localStorage.removeItem('access');
                localStorage.removeItem('refresh');
            }
        }
        await keycloak.init(data).catch(function (e) {
            console.debug(e);
        });

        authenticated.value = keycloak.authenticated;

        if (keycloak.token && keycloak.refreshToken) {
            token.access = keycloak.token;
            token.refresh = keycloak.refreshToken;
            localStorage.setItem('access', token.access);
            localStorage.setItem('refresh', token.refresh);
        }

        // registered
        if (authenticated.value) {
            await updateUser(locale);
            const profile = await keycloak.loadUserProfile();
            user.id = hash(profile.id); // Hash for  Analytics Tracking
        }

        // middleware
        useState('authenticated', () => authenticated.value);
        useState('registered', () => registered.value);
    }
    function refreshTokenHook(keycloak) {
        keycloak.onTokenExpired = () => {
            keycloak
                .updateToken(30)
                .then(() => {
                    localStorage.setItem('access', keycloak.token);
                    localStorage.setItem('refresh', keycloak.refreshToken);
                })
                .catch((err) => {
                    localStorage.removeItem('access');
                    localStorage.removeItem('refresh');
                });
        };
    }
    function accessTokenValid() {
        const token = localStorage.getItem('access');
        return jwtTokenValid(token);
    }
    function jwtTokenValid(token) {
        if (!token) return false;
        try {
            const decoded_token = jwtDecode(token);
            const exp = decoded_token.exp;
            if (Date.now() >= exp * 1000) {
                return false;
            }
        } catch (err) {
            return false;
        }
        return true;
    }
    function refreshTokenValid() {
        const token = localStorage.getItem('refresh');
        return jwtTokenValid(token);
    }

    async function login(redirectUri, locale) {
        localStorage.setItem('loginTry', '1');
        if (!keycloak) {
            await init();
        }
        const options = {};
        if (redirectUri) {
            options['redirectUri'] = redirectUri;
        }
        options['locale'] = locale;
        keycloak.login(options);
    }

    async function logout() {
        if (!keycloak) {
            await init();
        }
        authenticated.value = false;
        localStorage.removeItem('access');
        localStorage.removeItem('refresh');
        token.access = null;
        token.refresh = null;
        keycloak.logout({
            redirectUri: location.origin,
        });
        const oscar = useOscarClient();
        await oscar.logout();
    }

    async function register(redirectUrl, locale) {
        if (!keycloak) {
            await init();
        }
        const options = {};
        if (redirectUrl) {
            options['redirectUri'] = redirectUrl ? redirectUrl : location.href;
        }
        options['locale'] = locale;
        return keycloak.createRegisterUrl(options);
    }

    async function passwordReset(locale) {
        if (!keycloak) {
            await init();
        }
        localStorage.setItem('passwordResetInit', 1);
        return keycloak.login({
            action: 'UPDATE_PASSWORD',
            redirectUri: location.href,
            locale: locale,
        });
    }

    async function account(locale) {
        if (!keycloak) {
            await init();
        }

        let url = keycloak.createAccountUrl({
            redirectUri: location.href,
        });
        let localeQuery = `&kc_locale=${locale.value}`;
        return url.concat(localeQuery);
    }

    return {
        authenticated,
        registered,
        token,
        user,
        settings,
        specialPrice,
        init,
        passwordReset,
        login,
        register,
        logout,
        account,
        updateSettings,
        updateUser,
    };
});
