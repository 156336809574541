
// @ts-nocheck


export const localeCodes =  [
  "de",
  "fr",
  "en"
]

export const localeLoaders = {
  "de": [{ key: "../assets/locales/de.json", load: () => import("../assets/locales/de.json" /* webpackChunkName: "locale__usr_src_app_assets_locales_de_json" */), cache: true }],
  "fr": [{ key: "../assets/locales/fr.json", load: () => import("../assets/locales/fr.json" /* webpackChunkName: "locale__usr_src_app_assets_locales_fr_json" */), cache: true }],
  "en": [{ key: "../assets/locales/en.json", load: () => import("../assets/locales/en.json" /* webpackChunkName: "locale__usr_src_app_assets_locales_en_json" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../i18n.config.js?hash=c231e734&config=1" /* webpackChunkName: "__i18n_config_js_c231e734" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": ""
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": false,
    "dropMessageCompiler": true
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./i18n.config.js",
  "locales": [
    {
      "code": "de",
      "files": [
        "assets/locales/de.json"
      ]
    },
    {
      "code": "fr",
      "files": [
        "assets/locales/fr.json"
      ]
    },
    {
      "code": "en",
      "files": [
        "assets/locales/en.json"
      ]
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": true,
  "langDir": "assets/locales/",
  "rootRedirect": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": true,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "de",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "search/Search": {
      "de": "/suche/",
      "fr": "/recherche/"
    },
    "account/Overview": {
      "de": "/konto/",
      "fr": "/compte/"
    },
    "account/address/Overview": {
      "de": "/konto/adressen/",
      "fr": "/compte/adresse/"
    },
    "account/address/Add": {
      "de": "/konto/addressen/neu/",
      "fr": "/compte/adresse/nouveau/"
    },
    "account/address/Edit": {
      "de": "/konto/addressen/[id]/",
      "fr": "/compte/adresse/editer/:id/"
    },
    "checkout/Address": {
      "de": "/checkout/gast/",
      "fr": "/sortie-de-caisse/invite/"
    },
    "checkout/Shipping": {
      "de": "/checkout/versand/",
      "fr": "/sortie-de-caisse/expedition/"
    },
    "checkout/Summary": {
      "de": "/checkout/ubersicht/",
      "fr": "/sortie-de-caisse/resume/"
    },
    "checkout/Saferpay": {
      "de": "/checkout/bezahlung/",
      "fr": "/sortie-de-caisse/paiement/"
    },
    "checkout/PreLogin": {
      "de": "/checkout/",
      "fr": "/sortie-de-caisse/"
    },
    "checkout/ThankYou": {
      "de": "/checkout/danke/",
      "fr": "/sortie-de-caisse/merci/"
    },
    "account/OrderDetail": {
      "de": "/konto/bestellung/[id]",
      "fr": "/compte/commande/[id]"
    },
    "account/Member": {
      "de": "/konto/mitglied/",
      "fr": "/compte/profil"
    },
    "checkout/SaferpayCallback": {
      "de": "/checkout/:kind/:orderNumber/:hash",
      "fr": "/checkout/:kind/:orderNumber/:hash"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "redirectOn": "all",
  "i18nModules": []
}

export const nuxtI18nOptionsDefault = {
  "experimental": {
    "localeDetector": ""
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [],
  "defaultLocale": "",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": null,
  "rootRedirect": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false
}

export const normalizedLocales = [
  {
    "code": "de",
    "files": [
      {
        "path": "assets/locales/de.json"
      }
    ]
  },
  {
    "code": "fr",
    "files": [
      {
        "path": "assets/locales/fr.json"
      }
    ]
  },
  {
    "code": "en",
    "files": [
      {
        "path": "assets/locales/en.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const STRATEGIES = {
  "PREFIX": "prefix",
  "PREFIX_EXCEPT_DEFAULT": "prefix_except_default",
  "PREFIX_AND_DEFAULT": "prefix_and_default",
  "NO_PREFIX": "no_prefix"
}
export const DEFAULT_LOCALE = ""
export const DEFAULT_STRATEGY = "prefix_except_default"
export const DEFAULT_TRAILING_SLASH = false
export const DEFAULT_ROUTES_NAME_SEPARATOR = "___"
export const DEFAULT_LOCALE_ROUTE_NAME_SUFFIX = "default"
export const DEFAULT_DETECTION_DIRECTION = "ltr"
export const DEFAULT_BASE_URL = ""
export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"


