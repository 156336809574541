import { OscarClient } from '../api/oscar.js';

export function useOscarClient(locale) {
    const config = useRuntimeConfig();
    let url = config.public.apiBase;
    if (config.serverApiBase) {
        url = config.serverApiBase;
    }
    return new OscarClient(url, locale);
}
