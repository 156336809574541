import { navigateTo } from 'nuxt/app';
import { useAuthStore } from '~/stores/auth';
import { useI18NStore } from '~/stores/i18n';

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.hook('i18n:localeSwitched', ({ oldLocale, newLocale }) => {
        if (nuxtApp.$i18n.availableLocales.includes(newLocale)) {
            const i18nStore = useI18NStore();
            const url = i18nStore?.hreflang[newLocale];
            const authStore = useAuthStore();
            authStore.updateSettings(newLocale);
            if (authStore.authenticated) {
                authStore.updateUser(newLocale);
            }
            if (url) {
                navigateTo(url);
            } else {
                navigateTo(`/${newLocale}/`);
            }
        }
    });
    nuxtApp.hook('app:created', (vueApp) => {
        const pathLocale =
            vueApp.$nuxt.$router.currentRoute.value.path.split('/')[1];
        if (nuxtApp.$i18n.availableLocales.includes(pathLocale)) {
            const authStore = useAuthStore();
            authStore.updateSettings(pathLocale);
            if (pathLocale !== vueApp.$nuxt.$i18n.locale.value) {
                vueApp.$nuxt.$i18n.setLocale(pathLocale);
            }
        }
    });
});
